import React, { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import ValidateImage from "../../ValidateImage/ValidateImage";
import "./EditProfile.css";
import { v4 } from "uuid";

// firebase imports
import { auth, db, storage } from "../../../firebaseConfig";
import { updateEmail, updateProfile } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { deleteObject, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import SmallLoader from "../../Loader/SmallLoader";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";
import ConfirmModalBackdrop from "../../ConfirmModal/ConfirmModalBackdrop";
import ReactTooltip from 'react-tooltip';
import imageCompression from 'browser-image-compression';

import { getAnalytics, logEvent } from "firebase/analytics";

const EditProfile = ({
    user,
    setDisplayNameMain,
    setImageMain,
    setDescriptionMain,
    setEditProfile,
}) => {
    const initial = {
        image: user.image,
        displayName: user.displayName,
        description: user.description,
        email: user.email,
        phone: user.phone,
        linkedIn: user.linkedIn,
        website:  user.website,
        instagram:  user.instagram,
        youtube:  user.youtube,
        github: user.github,
        tiktok: user.tiktok
    };

    const analytics = getAnalytics();
    logEvent(analytics, 'edit_profile');

    const [confirm, setConfirm] = useState(false);
    const close = () => {
        if (
            image === initial.image &&
            displayName === initial.displayName &&
            description === initial.description &&
            email === initial.email &&
            phone === initial.phone &&
            linkedIn === initial.linkedIn &&
            website === initial.website &&
            instagram === initial.instagram &&
            youtube === initial.youtube &&
            github === initial.github &&
            tiktok === initial.tiktok
        ) {
            setEditProfile(false);
        } else {
            setConfirm(true);
        }
    };

    const [imagePreview, setImagePreview] = useState(user.image);
    const [image, setImage] = useState(user.image);
    const [displayName, setDisplayName] = useState(user.displayName);
    const [description, setDescription] = useState(user.description);
    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone);
    const [linkedIn, setLinkedin] = useState(user.linkedIn);
    const [website, setWebsite] = useState(user.website);
    const [instagram, setInstagram] = useState(user.instagram);
    const [youtube, setYoutube] = useState(user.youtube);
    const [github, setGithub] = useState(user.github);
    const [tiktok, setTiktok] = useState(user.tiktok);

    // update user data
    const updateProfileSubmit = async () => {
        setDisable(true);
        addImageToStorage();
    };

    const deleteOldProfileImage = async (oldImage) => {
        try {
            await deleteObject(ref(storage, oldImage));
        } catch (error) {
            console.log(error.code);
        }
    }

    const addImageToStorage = async () => {
        if (image === user.image) {
            if (image === "" || typeof image === "undefined") {
                addDocumentToDatabase(" ");
                return;
            }
            addDocumentToDatabase(image);
            return;
        }
        const imageRef = ref(
            storage,
            `profile-images/${user.userId}/${v4()}`
        );

        //compression details
        const imageFile = image;
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        
        try {
            const compressedFile = await imageCompression(imageFile, options);
            const uploadTask = uploadBytesResumable(imageRef, compressedFile);
            uploadTask.on(
                "state-changed",

                (snapshot) => {
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },

                (error) => {
                    // error in image upload
                    alert(">>> " + error);
                },

                () => {
                    // successful image upload
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        addDocumentToDatabase(downloadURL); //call add document function
                        if(initial.image !== "") {
                            deleteOldProfileImage(initial.image);
                        }
                    });
                }
            );
        } catch (error) { 
            console.log(error.code);
        }
    };

    const addDocumentToDatabase = async (imageLink) => {

        // username
        await updateProfile(auth.currentUser, { displayName: displayName }).then(
            async () => {
                await updateDoc(doc(db, "users", user.docId), {
                    image: imageLink || "",
                    displayName: displayName || "",
                    email: email || "",
                    description: description || "",
                    phone: phone || "",
                    linkedIn: linkedIn || "",
                    website: website || "",
                    instagram: instagram || "",
                    youtube: youtube || "",
                    github: github || "",
                    tiktok: tiktok || "",
                }).then(() => {
                    setDisable(false);
                    setEditProfile(false);
                });
            }).catch((error) => {
                alert(error);
            })
        
        window.location.reload();
    };

    const [disable, setDisable] = useState(false);

    return (
        <div
            className="EditProfile position-fixed top-50 start-50 translate-middle bg-white rounded-4 py-3 ps-5 pe-2 col-10 col-md-8 col-lg-6 d-flex flex-column"
            style={{ height: "95vh", zIndex: "20" }}
        >
            {
                confirm &&
                <>
                    <ConfirmModalBackdrop />
                    <ConfirmModal setShowMainModal={setEditProfile} setShowConfirmModal={setConfirm} />
                </>
            }

            <div className="pe-4" style={{ overflow: "auto" }}>
                {/* heading and exit */}
                <div className="container-fluid mg-0 p-0 d-flex justify-content-between align-items-center">
                    <h2 className="fw-bold m-0">Edit Profile</h2>
                    <AiFillCloseCircle size="30px" className="edit-img-hvr" onClick={() => close()} />
                </div>

                {/* image */}
                <div
                    className="rounded-circle overflow-hidden mt-2 mx-auto position-relative d-flex align-items-center justify-content-center bg-light edit-img-hvr"
                    data-for="editImage" data-tip
                    style={{ width: "10vmax", height: "10vmax" }}
                >


                    {imagePreview !== "" && imagePreview !== undefined ? (
                            <img src={imagePreview}  style={{ width: "auto", height: "100%" }} alt="" />
                        ) : (
                            <img src="/images/upload.svg" style={{ width: "30%", height: "30%" }} alt="" />
                        )}              

                    <ValidateImage
                        setImagePreview={setImagePreview}
                        setImage={setImage}
                    />
                </div>
                <div className="text-center">Upload Image: <span style={{ color: 'red' }}> .png .jpg | Max size: 10mb</span></div>
                <div className="text-center mb-3"> Optimal resolution: <span style={{ color: 'red' }}>at least 500x500px </span></div>
                <ReactTooltip id="editImage" place="right" effect="solid">
                        Upload image
                </ReactTooltip>

                {/* profile details */}
                <h5 className="mt-2">Profile Details</h5>

                {/* name */}
                <div className="form-group mt-1">
                    <label htmlFor="name" className="small">
                        Display Name
                    </label>
                    <input
                        type="text"
                        className="form-control mt-1 py-1"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                    />
                </div>
                {/* description */}
                <div className="form-group mt-1">
                    <label className="small">Description</label>
                    <textarea
                        className="form-control mt-1 py-1"
                        value={description}
                        maxLength="300"
                        
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>

                {/* contact information */}
                <h5 className="mt-5">Contact Information</h5>

                {/* email */}
                <div className="form-group mt-1">
                    <label htmlFor="name" className="small">
                        Email
                    </label>
                    <input
                        type="Email"
                        className="form-control mt-1 py-1"
                        maxLength="70"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                {/* phone */}
                <div className="form-group mt-1">
                    <label className="small">Phone</label>
                    <input
                        // onKeyPress={(event) => {
                        //     if (!/[0-9]/.test(event.key)) {
                        //         event.preventDefault();
                        //     }
                        // }}
                        type="text"
                        className="form-control mt-1 py-1"
                        placeholder="000-000-0000"
                        maxLength="15"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>
                {/* linkedIn */}
                <div className="form-group mt-1">
                    <label className="small">LinkedIn</label>
                    <input
                        type="text"
                        className="form-control mt-1 py-1"
                        maxLength="70"
                        placeholder="https://"
                        value={linkedIn}
                        onChange={(e) => setLinkedin(e.target.value)}
                    />
                </div>

                {/* Website */}
                <div className="form-group mt-1">
                    <label className="small">Website</label>
                    <input
                        type="text"
                        className="form-control mt-1 py-1"
                        maxLength="70"
                        placeholder="https://"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                    />
                </div>

                {/* Instagram */}
                <div className="form-group mt-1">
                    <label className="small">Instagram</label>
                    <input
                        type="text"
                        className="form-control mt-1 py-1"
                        maxLength="70"
                        placeholder="https://"
                        value={instagram}
                        onChange={(e) => setInstagram(e.target.value)}
                    />
                </div>

                {/* youtube */}
                <div className="form-group mt-1">
                    <label className="small">Youtube</label>
                    <input
                        type="text"
                        className="form-control mt-1 py-1"
                        maxLength="70"
                        placeholder="https://"
                        value={youtube}
                        onChange={(e) => setYoutube(e.target.value)}
                    />
                </div>

                {/* github */}
                <div className="form-group mt-1">
                    <label className="small">Github</label>
                    <input
                        type="text"
                        className="form-control mt-1 py-1"
                        maxLength="70"
                        placeholder="https://"
                        value={github}
                        onChange={(e) => setGithub(e.target.value)}
                    />
                </div>

                {/* tiktok */}
                <div className="form-group mt-1">
                    <label className="small">Tiktok</label>
                    <input
                        type="text"
                        className="form-control mt-1 py-1"
                        maxLength="70"
                        placeholder="https://"
                        value={tiktok}
                        onChange={(e) => setTiktok(e.target.value)}
                    />
                </div>

                <div className="mt-2"><span style={{ color: 'red' }}>Please make sure to use URLs starting with https://</span></div>

                {/* save button */}
                <div className="container-fluid mt-5 p-0 m-0 text-end">
                    <button
                        onClick={updateProfileSubmit}
                        className="btn-primary px-4"
                        disabled={disable}
                    >
                        {disable === true ? <SmallLoader /> : "Save"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditProfile;
