import styled from "styled-components"
import { useState, queryString } from 'react';
import { useNavigate } from "react-router-dom";
import { FaBirthdayCake } from "react-icons/fa";
// imports for authentication
import { Timestamp } from "@firebase/firestore";
import { db } from "../../../firebaseConfig";
import { addDoc, collection, getDocs, limit, query, where  } from "firebase/firestore";
// react router dom
import ReCAPTCHA from "react-google-recaptcha";

const EarlyAccess = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [emailFound, setEmailFound] = useState(false);
    const [dobCheck, setDobCheck] = useState(false);
    const [invited, setInvited] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");

    const [errorMsg, setErrorMsg] = useState("");

    // captcha
    const [captcha, setCaptcha] = useState("");
    function onChange(value) {
        setCaptcha(value);
    }

    const validEmail = new RegExp(
        '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
     );


    const handleSubmit = async (event) => {
        event.preventDefault();
        //1. try to locate email and send pw reset email
        if (captcha === "") {
            setErrorMsg("Captcha required");
            return;
        }

        if(validEmail.test(email) === false) {
            setErrorMsg("Invalid email address");
            return;
        }
        
        //Age check
        var bday=dob.split("-");
        var bday_in_milliseconds = new Date(parseInt(bday[0]), 10, parseInt(bday[1], 10) - 1 , parseInt(bday[2], 10)).getTime(); //birth-date in milliseconds
        var now = new Date().getTime(); //current time in milliseconds
        if(now - bday_in_milliseconds < 410240038000){ //410240038000 is 13 years in milliseconds
            setDobCheck(true);
            return;
        }

        try {

            const querySnapshot = await getDocs(
                query(
                    collection(db, "invitation")
                )
            );

            for(let i = 0; i < querySnapshot.docs.length; i++) {
                if (querySnapshot.docs[i].data().email.toLowerCase() == email.toLowerCase()) {
                    console.log("email exists");
                    setEmailFound(true);
                    return;
                }
            }

            await addDoc(collection(db, "invitation"), {
                email: email.toLowerCase(),
                firstName: firstName,
                lastName: lastName,
                dob: dob,
                date_created: Timestamp.fromDate(new Date()),
            });
            setInvited(true);
        } catch(error) {
            console.log(error);
        }
        
    };

    return (
        <Container>
            <div className="container">
                <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-center justify-content-center pt-4 pr-4">
                        { (invited !== true ? 
                            <form className="bg-white" onSubmit={(handleSubmit)}>

                                { (errorMsg !== "" ? 
                                <h6 className="p-2 border text-center justify-content-center rounded-3" style={{ backgroundColor: '#DE5654',color: 'white' }}>{errorMsg}</h6> : <p></p>
                                )}

                                <h1 className="pb-2 text-secondary border-bottom">Welcome to brinkfolio!</h1> <br/>
                                <h5 className="pb-2 text-secondary"> Register below and we'll send you an invite as soon as possible!</h5>

                                <div className="input form-group d-flex mt-3">
                                    <input 
                                        type="text" 
                                        className="input-field form-control sign-up-form" 
                                        name="firstName" 
                                        value={firstName} 
                                        required onChange={(e) => setFirstName(e.target.value)}/>
                                    <label className="input-label">First Name</label>
                                </div>

                                <div className="input form-group d-flex mt-3">
                                    <input 
                                        type="text" 
                                        className="input-field form-control sign-up-form" 
                                        name="lastName" 
                                        value={lastName} 
                                        required onChange={(e) => setLastName(e.target.value)}/>
                                    <label className="input-label">Last Name</label>
                                </div>

                                <div className="input form-group d-flex mt-3">
                                    <input 
                                        type="email" 
                                        className="input-field form-control sign-up-form" 
                                        name="email" 
                                        value={email} 
                                        required onChange={(e) => setEmail(e.target.value)}/>
                                    <label className="input-label">Email</label>
                                    
                                </div>
                                { (emailFound ?
                                <h6 className="mt-1" style={{ color: 'red' }}>You're already on the list!</h6> : <span></span>)
                                }
                                <div className="form-group d-flex align-items-center mt-3">
                                <FaBirthdayCake />    
                                    <input
                                        type="date"
                                        className="form-control sign-up-form mx-2"
                                        value={dob}
                                        required onChange={(e) => setDob(e.target.value)}/>  
                                </div>
                                { (dobCheck ?
                                <h6 className="mt-1" style={{ color: 'red' }}>You must be 13 years or older to join</h6> : <span></span>)
                                }
                                
                                <div className="form-check d-flex justify-content-center mt-5 mb-3">
                                    
                                    <label className="form-check-label" htmlFor="terms">
                                        By clicking Sign up, I agree all of the statements in brinkfolio's {" "}
                                        <span
                                            className="text-decoration-underline text-primary"
                                            onClick={() => navigate("/privacypolicy")}
                                        >
                                            Privacy Policy
                                        </span>
                                        {" "}
                                        and
                                        {" "}
                                        <span
                                            className="text-decoration-underline text-primary"
                                            onClick={() => navigate("/termsofservice")}
                                        >
                                            Terms of Service
                                        </span>
                                    </label>
                                </div>

                                <div className="col-12 d-flex justify-content-center mb-3">
                                    <div className="reCaptcha">
                                        <ReCAPTCHA
                                            id="captcha1"
                                            sitekey="6LeDIpgiAAAAAHMPb4lCKzqogeFp3mQXF7pr0wje"
                                            required onChange={onChange}
                                        />
                                    </div>
                                </div>

                                <div className="action col-12 d-flex justify-content-center">
                                    <button 
                                        className="btn btn-primary px-5" 
                                        type="submit">Sign Up</button>
                                </div>

                                <div className="col-12 text-center mt-4">
                                    <p>
                                        Already have an account? &nbsp;
                                        <span
                                            role={"button"}
                                            className="text-primary text-decoration-underline"
                                            onClick={() => navigate("/login")}
                                        >
                                            Log in
                                        </span>
                                    </p>
                                </div>
                            </form >
                        :
                        <div>
                        <h5 className="pb-2 text-secondary"> You've been added on the list! We will send you an invitation soon!</h5>
                        <h6> Learn more about us! </h6>
                        <span 
                                            className="text-decoration-underline text-primary"
                                            onClick={() => navigate("/about")}
                                        >
                                            About
                                        </span>
                        </div>
                        )}
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 border-start">
                        <img className="img-fluid" src="/images/welcome_home.png" alt="brinkfolio welcome image" />
                    </div>
                </div>
            </div>
        </Container>
    );
};


const Container = styled.div`
    margin-top: 50px;

    *, *:after, *:before {
        box-sizing: border-box;
    }

    body {
        font-family: "DM Sans", sans-serif;
        line-height: 1.5;
        background-color: #f1f3fb;
        padding: 0 2rem;
    }

    img {
        max-width: 100%;
        display: block;
    }

    .choice {
        text-align: center;
        padding-bottom: 5px;
    }


    // iOS Reset 
    input {
        appearance: none;
        border-radius: 0;
    }

    .card {
        margin: 1rem auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 400px;
        max-width: 425px;
        background-color: #FFF;
        border-radius: 10px;
        box-shadow: 0 10px 20px 0 rgba(#999, .25);
        padding: .75rem;
    }

    .card-bg {
        border-radius: 8px;
        overflow: hidden;
        padding-bottom: 50%;
        background-image: url();
        background-repeat: no-repeat;
        background-size: 150%;
        background-position: 0 5%;
        background-color: rgba(145, 226, 194, .5);
        background-color: rgba(105, 155, 216, .5);
        position: relative;
    }

    .card-header {
        position: absolute;
        left: 10%;
        top: 20%;
        right: 10%;
        font-size: 1.75rem;
        font-weight: 700;
        color: #00377A;
        line-height: 1.222;
        small {
            display: block;
            font-size: .75em;
            font-weight: 400;
            margin-top: .25em;
        }
    }

    .card-form {
        padding: 2rem 1rem 0;
    }

    .input {
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        padding-top: 1.0rem;
        &+.input {
            margin-top: 1.5rem;
        }
    }

    .input-label {
        color: #8597a3;
        position: absolute;
        top: 1.5rem;
        transition: .25s ease;
    }

    .input-field {
        border: 0;
        z-index: 1;
        background-color: transparent;
        border-bottom: 2px solid #eee; 
        font: inherit;
        font-size: 1.125rem;
        padding: .25rem 0;
        &:focus, &:valid {
            outline: 0;
            border-bottom-color: #6658d3;
            &+.input-label {
                color: #6658d3;
                transform: translateY(-2rem);
            }
        }
    }

    .action {
        margin-top: 1.5rem;
    }

    .action-button {
        font: inherit;
        font-size: 1.25rem;
        padding: .5em;
        width: 50%;
        font-weight: 500;
        border-radius: 15px;
        color: #FFF;
        border: 0;
        &:focus {
            outline: 0;
        }

        background-color: rgba(111, 85, 184, .8);
        margin-bottom: 20px;
        &:hover {
            background-color: rgba(111, 85, 184, 1);
            color: #fff;
            text-decoration: none;
        }




    }

    .card-info {
        padding: 1rem 1rem;
        text-align: center;
        font-size: .875rem;
        color: #8597a3;
        a {
            display: block;
            color: #6658d3;
            text-decoration: none;
        }
    }

`;


export default EarlyAccess;
