import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { MdAlternateEmail, MdPassword } from "react-icons/md";
import Loader from "../../../Components/Loader/Loader";
// react router dom
import { useNavigate } from "react-router-dom";
// imports for authentication
import {
    fetchSignInMethodsForEmail,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "../../../firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";
import { addDoc, collection, getDocs, limit, query, Timestamp, where } from "firebase/firestore";
import ReCAPTCHA from "react-google-recaptcha";


const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, loading } = useSelector(selectUser);
    const [email, setEmail] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [passwordReset, setPasswordReset] = useState(false);

    // captcha
    const [loginFail, setLoginFail] = useState(0);
    const [captcha, setCaptcha] = useState("");
    function onChange(value) {
        setCaptcha(value);
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
        forgotPassword();
    }


    // Forget Password
    const forgotPassword = () => {
        if (email === "") {
            setErrorMsg("Enter email to reset password");
            return;
        }

        sendPasswordResetEmail(auth, email)
            .then(() => {
                setErrorMsg("");
                setPasswordReset(true);
            })
            .catch((error) => {
                console.log(error.code);
                if (error.code === "auth/invalid-email")
                {
                    setErrorMsg("Incorrect email entered. Please check your email");
                } else if (error.code === "auth/user-not-found") {
                    setErrorMsg("Incorrect email entered. Please check your email")
                } else{
                    console.log(error.code);
                }
            });
            navigate('/forgotsent');
    }


    return (
        loading === true ?
            <Loader />
            :
            <Container>
            <div className="container big-div">
                <div className="row">
                    
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-center justify-content-center pt-4 pr-4">

                        <CardForm className="bg-white" onSubmit={(handleSubmit)}>
                            
                            { (errorMsg !== "" ? 
                            <h6 className="p-2 border text-center justify-content-center rounded-3" style={{ backgroundColor: '#DE5654',color: 'white' }}>{errorMsg}</h6> : <p></p>
                            )}
                            <h1 className="pb-2 text-secondary border-bottom">Forgot your password?</h1>
                            <p>It happens. We'll send you instructions to reset it!</p>
                            <p>Please enter your email below!</p>

                            <CardInput>
                                <InputField 
                                    type="email" 
                                    name="email" 
                                    value={email} 
                                    required onChange={(e) => setEmail(e.target.value)}/>
                                <label className="input-label">Email</label>
                            </CardInput>

                            <div className="col-12 mt-4 d-flex justify-content-center">
                                <div className="reCaptcha">
                                    <ReCAPTCHA
                                        id="captcha2"
                                        sitekey="6LeDIpgiAAAAAHMPb4lCKzqogeFp3mQXF7pr0wje"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="action col-12 d-flex justify-content-center">
                            
                                <button className="btn btn-primary" type="submit">Submit</button>
                                
                            </div>
                            <div className="justify-content-center d-flex">
                                { (passwordReset === true? 
                                    <span style={{ color: 'green' }} > Password reset email has been sent!</span> : <p></p>)
                                }
                            </div>
                            
                        </CardForm>

                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 border-start">
                        <img class="img-fluid" src="/images/welcome_home.png" alt="brinkfolio welcome image" />
                    </div>
                </div>


            </div>
        </Container>
    );
};

const Container = styled.div`
    margin-top: 50px;

    *, *:after, *:before {
        box-sizing: border-box;
    }

    body {
        font-family: "DM Sans", sans-serif;
        line-height: 1.5;
        background-color: #f1f3fb;
        padding: 0 2rem;
    }

    img {
        max-width: 100%;
        display: block;
    }

    .choice {
        text-align: center;
        padding-bottom: 10px;
    }


    // iOS Reset 
    input {
        appearance: none;
        border-radius: 0;
    }


    .forgot {
         margin-top: 20px;

         a {
            text-decoration: none;
         }

         &:hover {
            text-decoration: underline;
         }

    }
    .input-label {
        color: #8597a3;
        position: absolute;
        top: 1.5rem;
        transition: .25s ease;
    }

    

    .action {
        margin-top: 1.5rem;
    }

    .action-button {
        font: inherit;
        font-size: 1.25rem;
        padding: .5em;
        width: 50%;
        font-weight: 500;
        border-radius: 15px;
        color: #FFF;
        border: 0;
        &:focus {
            outline: 0;
        }

        background-color: rgba(111, 85, 184, .8);
        margin-bottom: 20px;
        &:hover {
            background-color: rgba(111, 85, 184, 1);
            color: #fff;
            text-decoration: none;
        }




    }

    .card-info {
        padding: 1rem 1rem;
        text-align: center;
        font-size: 1rem;

        color: #8597a3;
        a {
            margin-top: 5px;
            display: block;
            color: #6658d3;
            text-decoration: none;
        }
    }

`;

const CardForm = styled.form`
    padding: 2rem 1rem 0;
`;

const CardInput = styled.div`
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    padding-top: 1.5rem;
    &+.input {
        margin-top: 1.5rem;
    }
`;

const InputField = styled.input`
    border: 0;
    z-index: 1;
    background-color: transparent;
    border-bottom: 2px solid #eee; 
    font: inherit;
    font-size: 1.125rem;
    padding: .25rem 0;
    &:focus, &:valid {
        outline: 0;
        border-bottom-color: #6658d3;
        &+.input-label {
            color: #6658d3;
            transform: translateY(-1.5rem);
        }
    }
`;
export default ForgotPassword;
