import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from "./Components/Navbar/Navbar";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import MyProfile from './Pages/MyProfile/MyProfile';
import UserProfile from './Pages/UserProfile/UserProfile';
// import router
import { BrowserRouter, Routes, Route } from "react-router-dom";
// firebase imports
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebaseConfig';
import { logIn, logOut } from './features/userSlice';
import { useDispatch } from 'react-redux';
import { collection, getDocs, limit, query, where } from 'firebase/firestore';
import ProjectPage from './Pages/ProjectPage/ProjectPage';
import Account from './Pages/Account/Account';
import PrivacyPolicy from './Pages/Text/PrivacyPolicy';
import TermsOfService from './Pages/Text/TermsOfService';
import About from './Pages/Text/About';
import Welcome from './Pages/Text/Welcome';
import Survey from './Pages/Text/Survey';
import PressRelease from './Pages/Text/PressRelease';
import Howto from './Pages/Text/Howto';
import ProductUpdates from './Pages/Text/ProductUpdates';
import SignUp from './Pages/Home/SignUp/SignUp';
import SignIn from './Pages/Home/SignIn/SignIn';
import Forgot from './Pages/Home/ForgotPassword/ForgotPassword';
import ConfirmForgotPassword from './Pages/Home/ForgotPassword/ConfirmForgotPassword'
import NotFound from './Pages/Errors/NotFound';
import EarlyAccess from './Pages/Home/EarlyAccess/EarlyAccess';
import ReactGA from 'react-ga';

import { getAnalytics, logEvent } from "firebase/analytics";
import ForgotPassword from './Pages/Home/ForgotPassword/ForgotPassword';

import { QueryClient, QueryClientProvider } from "react-query";

const App = () => {
  const dispatch = useDispatch();
  const client = new QueryClient();

  const TRACKING_ID = "G-YK3P8FVZ71"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    const checkAuthState = () => onAuthStateChanged(auth, (userAuth) => {
      if (userAuth) {
        const q = query
          (
            collection(db, "users"),
            where("user_id", "==", userAuth.uid),
            limit(1)
          )
        getDocs(q).then((querySnapshot) => {
          dispatch(
            logIn({
              uid: userAuth.uid,
              email: userAuth.email,
              name: userAuth.displayName,
              dob: querySnapshot.docs[0].data().dob,
            })
          );
        })
      } else {
        dispatch(logOut());
      }
    });

    checkAuthState();
    return () => console.log("")
  }, []);

  return (
        <div className="App">
          <BrowserRouter>
            
            <Routes>


              <Route
                path='/signup'
                element={<><Header /> <EarlyAccess /></>}
              />

              <Route
                path='/sign-up'
                element={<><Header /> <EarlyAccess /></>}
              />

              <Route
                path='/login'
                element={<><Header /> <SignIn /></>}
              />

              <Route
                path='/forgot'
                element={<><Header /> <Forgot /></>}
              />

              {/*
              <Route
                path='/register'
                element={<><Header/> <SignUp /></>}
              />
              */}
              
              <Route
                path='/profile'
                element={<><Navbar /><MyProfile /></>}
              />

              <Route
                path='/account'
                element={<><Navbar /><Account /></>}
              />

              <Route
                path='/user/:id'
                element={<><Navbar /><UserProfile /></>}
              />

              <Route
                path='/project/:id'
                element={<><Navbar /><ProjectPage /></>}
              />

              <Route
                path='/privacypolicy'
                element={<><Header /><PrivacyPolicy /></>}
              />

              <Route
                path='/termsofservice'
                element={<><Header /><TermsOfService /></>}
              />

              <Route
                path='/about'
                element={<><Header /><About /></>}
              />

              <Route
                path='/how-to'
                element={<><Header /><Howto /></>}
              />

              <Route
                path='/survey'
                element={<><Header /><Survey /></>}
              />

              <Route 
                path='/'
                element={<><Header /><Welcome /></>}
              />
              
              <Route 
                path='/pressrelease'
                element={<><Header /><PressRelease /></>}
              />
              
              {
              <Route 
                path='/updates'
                element={<><Header /><ProductUpdates /></>}
              />  
              }
              
              <Route 
                path='/forgotsent'
                element={<><Header /><ConfirmForgotPassword /></>}
              />

              <Route
                path='*'
                element={<><Header /><NotFound /></>}
              />

            </Routes>
            <Footer />
          </BrowserRouter>
        </div>
  );
}

export default App;
