import React from 'react'

const ConfirmDeleteModal = ({ setShowMainModal, setShowConfirmModal, confirmDelete }) => {

    const close = () => {
        confirmDelete(true);
        setShowConfirmModal(false);
    }
    
    return (
        <div className="bg-white rounded-4 border shadow position-absolute translate-middle top-50 start-50 d-flex flex-column align-items-center justify-content-evenly p-4"
            style={{ zIndex: "110", height: "30%", width: "60%" }}
        >

            <h4 >Are you sure you want to delete the project? This cannot be undone.</h4>
                <div>
                    <button
                        className="btn rounded-1 btn-outline-success mx-3"
                        onClick={() => setShowConfirmModal(false)}
                    >
                        Cancel
                </button>
                <button
                    className="btn rounded-1 btn-danger mx-3 px-4"
                    onClick={() => close()}
                >
                    Delete
                </button>
            </div>
        </div>
    )
}

export default ConfirmDeleteModal