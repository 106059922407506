import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

// firebase imports
import { auth, db, storage } from "../../firebaseConfig";
import { sendEmailVerification, updateEmail, updatePassword, updateProfile } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { logIn, selectUser } from "../../features/userSlice";
import Loader from "../../Components/Loader/Loader";
import { collection, getDocs, limit, query, where } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";

// imports for authentication
import SmallLoader from "../../Components/Loader/SmallLoader";
import {
    sendPasswordResetEmail,
} from "firebase/auth";


const Account = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const { user, loading } = useSelector(selectUser);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [userURL, setUserURL] = useState("");
    const [dob, setDob] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [resume, setResume] = useState("");
    const [disable, setDisable] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);

    /** OlD UPDATE PW CODE */
    /*
    const updatePassword = async() => {
        await updatePassword(auth.currentUser, password)
        .then(() => {
            dispatch(
                logIn({
                    uid: user.uid,
                    email: user.email,
                    dob: dob,
                })
            );
        }).catch((error) => {
            alert(error.code)
            alert("* Error updating password !");
        })
        alert("* Password Updated !");
        setDisable(false);
    }
    */

    const resetPassword = () => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                alert("* Reset email has been sent !");
            })
            .catch((error) => {
                alert("*" + error.code);
            });
    }

    // update user data
    const updateProfileSubmit = async () => {

        if (password !== confirmPassword) {
            alert("*passwords don't match");
            return;
        }
        setDisable(true);

        // username
        await updateProfile(auth.currentUser, { displayName: name }).then(
            async () => {
                await updateEmail(auth.currentUser, email)
                    .then(async () => {
                        // email
                        const q = query
                            (
                                collection(db, "users"),
                                where("user_id", "==", user.uid),
                                limit(1)
                            )

                        // user data
                        await updateDoc(doc(db, "users", (await getDocs(q)).docs[0].id), {
                            email: email,
                            dob: dob,
                        })
                        setDisable(false);
                    })
                    .catch((error) => {
                        alert(error.message);
                        setDisable(false);
                    });
                    window.location.reload();
            }
            
        );
    };

    // Verify Email
    const verifyEmail = async () => {
        if (email === "") {
            alert("* Enter email to get verification link");
            return;
        }

        await sendEmailVerification(auth.currentUser)
            .then(() => {
                alert(`*verification email has been sent to ${email}!`)
            }).catch((error) => {
                console.log(error)
            })
    };

    // upload resume
    const uploadResume = async () => {
        // fetch user data
        const querySnapshot = await getDocs(
            query(
                collection(db, "users"),
                where("user_id", "==", user.uid)
            )
        );

        if (querySnapshot.docs[0].data().resume) {
            const fileRef = ref(storage, querySnapshot.docs[0].data().resume);
            deleteObject(fileRef).then(() => {
                console.log("deleted old resume file")
            }).catch((error) => {
                console.log(error)
            })
        }

        const resumeRef = ref(
            storage,
            `user-resume/${name.split(" ").join("") + "Resume" + v4()}`
        );
        const uploadTask = uploadBytesResumable(resumeRef, resume);
        uploadTask.on(
            "state-changed",

            (snapshot) => {
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
            },

            (error) => {
                // error in resume upload
                alert(">>> " + error);
            },

            () => {
                // successful resume upload and add resume link to document
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    const q = query
                        (
                            collection(db, "users"),
                            where("user_id", "==", user.uid),
                            limit(1)
                        )

                    // user data
                    await updateDoc(doc(db, "users", (await getDocs(q)).docs[0].id), {
                        resume: downloadURL,
                    }).then(() => {
                        alert("* resume updated !");
                    });

                });
            }
        );

    }

    useEffect(() => {
        if (loading === false) {
            if (user === null) {
                navigate("/");
            } else {
                setName(auth.currentUser.displayName);
                setEmailVerified(auth.currentUser.emailVerified);
                var url = window.location.protocol + "//" + window.location.host + "/user/" + user.uid;
                setUserURL(url);
                setEmail(auth.currentUser.email);
                setDob(user.dob);
            }
        }
    }, [user, loading, navigate]);


    return (
        loading === true ?
            <Loader />
            :
            <div className="profile-bg " style={{ height: "100vh" }}>
            <div className="EditProfile bg-white rounded-4 my-5 mx-auto py-3 ps-3 pe-1 ps-md-5 pe-md-2 col-11 col-md-8 col-lg-6 d-flex flex-column ">
                <div className="pe-4" style={{ overflow: "auto" }}>
                    {/* heading and exit */}
                    <h2 className="fw-bold m-0 mb-5 profile-title">Account</h2>

                    {/* name 
                    <div className="form-group my-3">
                        <label htmlFor="name" className="small">
                             Name
                        </label>
                        <input
                            type="text"
                            className="form-control mt-1 py-1"
                            value={name}
                        />
                    </div>
                    */}

                    {/* dob */}
                    <div className="form-group my-3">
                        <label htmlFor="dob" className="small">
                            DOB
                        </label>
                        <input
                            type="date"
                            className="form-control mt-1 py-1"
                            value={dob}
                            onChange={(e) => setDob(e.target.value)}
                        />
                    </div>

                    {/* email */}
                    <div className="form-group my-3">
                        <label htmlFor="name" className="small">
                            Email
                        </label>
                        <div className="position-relative">
                            <input
                                type="Email"
                                className="form-control mt-1 py-1 pe-5"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />

                            { (!emailVerified? 
                            <button
                                onClick={() => verifyEmail()}
                                className="position-absolute w-25 h-100 top-0 end-0 btn btn-sm btn-primary px-0"
                            >
                                verify <span className="d-none d-md-inline-block">email</span>
                            </button> : 
                            <p></p>)
                            
                            }
                        </div>
                    </div>

                    {/* shareable Link */}
                    <div className="form-group my-3">
                        <label htmlFor="name" className="small">
                            User brinkfolio URL
                        </label>
                        <div className="position-relative">
                            <input
                                type="userUrl"
                                className="form-control mt-1 py-1 pe-5"
                                style={{ color: "grey" }}
                                value={userURL}
                                readOnly
                            />

                            <button
                                onClick={() =>  navigator.clipboard.writeText(userURL)}
                                className="position-absolute w-10 h-100 top-0 end-0 btn btn-sm "
                            >
                                <img src="/images/copy.svg"></img>
                            </button>
                        </div>
                    </div>

                    {/*PW Reset*/}
                    <div className="form-group my-3">
                        <button
                            role={"button"}
                            className="btn-primary px-5"
                            onClick={() => resetPassword()}
                        >
                            Password Reset
                        </button>
                    </div>

                    {/* save button */}
                    <div className="container-fluid mt-3 mb-3 px-5 m-0 text-center">
                        <button
                            onClick={updateProfileSubmit}
                            className="btn-primary px-5"
                            disabled={disable}
                        >
                            {
                                disable === true ?
                                    <SmallLoader />
                                    :
                                    "Save"
                            }
                        </button>
                    </div>

                    {/* resume TEMPORARILY DISABLED *******************************
                    <div className="form-group my-3">
                        <label htmlFor="name" className="small">
                            Resume
                        </label>
                        <div className="position-relative">
                            <input
                                type="file"
                                className="form-control mt-1 py-1 pe-5"
                                onChange={(e) => setResume(e.target.files[0])}
                                multiple={false}
                                accept=".pdf, .doc"
                            />

                            <button
                                onClick={() => uploadResume()}
                                className="position-absolute w-25 h-100 top-0 end-0 btn btn-sm btn-primary px-0"
                            >
                                upload
                            </button>
                        </div>
                    </div>
                        */}

                </div>
            { /** 
            </div>

                <div className="EditProfile bg-white rounded-4 my-5 mx-auto py-3 ps-3 pe-1 ps-md-5 pe-md-2 col-11 col-md-8 col-lg-6 d-flex flex-column">
                    <div className="pe-4" style={{ overflow: "auto" }}>
                        
                        <h2 className="fw-bold m-0 mb-5">Password Reset</h2>

                        
                        <div className="form-group my-3">
                            <label htmlFor="password" className="small">
                                New Password
                            </label>
                            <input
                                type="password"
                                className="form-control mt-1 py-1"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        
                        <div className="form-group my-3">
                            <label htmlFor="confirmPassword" className="small">
                                Confirm New Password
                            </label>
                            <input
                                type="password"
                                className="form-control mt-1 py-1"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>

                       
                        <div className="container-fluid mt-3 mb-3 px-5 m-0 text-center">
                            <button
                                onClick={updatePassword}
                                className="btn btn-sm btn-outline-primary px-4"
                                disabled={disable}
                            >
                                {
                                    disable === true ?
                                        <SmallLoader />
                                        :
                                        "Reset Password"
                                }
                            </button>
                        </div>

                    </div>
                    */}


                </div>
            </div>
    )
};

export default Account;
