import React, { useEffect } from 'react'

const ProductUpdates = () => {
    return (
		<div className="big-div">
			<section className="container mt-5">
				<div className="row">
					<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
					<h1 className="public-title">What's new with brinkfolio?</h1>
					<h2 className="public-title-secondary">We're working hard to help our customers showcase their work</h2>
					</div>
					<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
					<figure>
						<img className="img-fluid float-end" alt="Product updates from brinkfolio" src="/images/company/ProductUpdates.png" />
						<figcaption className="figure-caption text-end">Image by storyset / Freepik</figcaption>
					</figure>
					</div>
				</div>
			</section>
			<hr />
			<section className="container mb-5">
				<div className="row">
					<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
						<span className="badge text-bg-success mb-2">Success</span>
						<h3 className="public-title">December 12, 2022</h3>
					</div>
					<div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8">
					<h4 className="public-title-secondary">brinkfolio updates</h4>
						<ul>
						<li>New home page</li>
						<li>How-to guide section to help you create an amazing brinkfolio to show off to hiring managers</li>
						<li>Ability to reorder your projects</li>
						<li>Image viewer for your project gallery</li>
						</ul>
					</div>
				</div>
				<hr />     
				<div className="row">
					<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
						<span className="badge text-bg-danger mb-2">Warning</span>
						<h3 className="public-title">Oct 28 - 30, 2022</h3>
					</div>
					<div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8">
					<h4 className="public-title-secondary">brinkfolio will be down for maintenance</h4>
						<p>Our app is down, but don't worry, when we're back up we'll be better than ever!</p>
					</div>
				</div>
				<hr /> 
				<div className="row">
					<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
						<span className="badge text-bg-success mb-2">Success</span>
						<h3 className="public-title">Sept 21, 2022</h3>
						
					</div>
					<div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8">
						<h4 className="public-title-secondary">brinkfolio is out of stealth</h4>
						<p>brinkfolio, say hello to the world!</p>
					</div>
				</div>
			</section>
		</div>
    )
}

export default ProductUpdates;