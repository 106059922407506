import React from 'react'
import "./About.css";

const About = () => {
    return (
	<div className="big-div">
		<section className="container">
			<div className="row my-5">
				<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 text-start">
					<h1 className="public-title">At the brink of a change?</h1>
					<p>brinkfolio is a professional social media platform that promotes the next generation of industry professionals.</p>
					<p>Our platform is focused on creating an easy to use, visual, and engaging professional portfolio to advance careers and grow your personal brands.</p>
				</div>
				<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
					<img className="img-fluid float-end" alt="Businessman leaving comfort zone" src="/images/company/comfort.jpg" />
					<p className="figure-caption text-end">Image by pch.vector / Freepik</p>
				</div>
			</div>
		</section>
		<hr />
		<section className="container">
			<div className="row">
				<div className="col-12">
					<h2 className="public-title text-center">Our values</h2>
				</div>
				<div className="row">
					<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
						<img className="img-fluid" src="/images/company/talent.png" alt="Show your talent on brinkfolio's platform" />
						<p className="figure-caption text-center">Image by vectorjuice / Freepik</p>
						<h3 className="text-center public-title-secondary">Your talent speaks volumes</h3>
						<p className="text-start">At brinkfolio we know everyone travels a unique journey. We don't believe that lack of connections or finances should hold you back.</p>
					</div>
		​
					<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
						<img className="img-fluid" src="/images/company/dni.png" alt="brinkfolio supports our diverse customers" />
						<p className="figure-caption text-center">Image by pch.vector / Freepik</p>
						<h3 className="text-center public-title-secondary">Diversity and Inclusion</h3>
						<p className="text-start">We don't just talk the talk, we walk the walk. Our team is comprised of many that represents the underrepresented.</p>
					</div>
		​
					<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
						<img className="img-fluid" src="/images/company/integrity.png" alt="brinkfolio makes a promise" />
						<p className="figure-caption text-center">Image by storyset / Freepik</p>
						<h3 className="text-center public-title-secondary">Integrity and Honesty</h3>
						<p className="text-start">Our product would be nothing without the community that builds it. From our employees to our customers, the key to our success is built on your trust in us.</p>
					</div>			
				</div>
			</div>
		</section>
		​
		<hr />
		<section className="container">
			<div className="row">
				<div className="col-12">
					<h2 className="public-title text-center">Our people</h2>
				</div>
				<div className="row">
					<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
						<img className="img-fluid mb-2" src="/images/company/Hubert.jpg" alt="" />
						<h3 className="text-center public-title-secondary">Hubert John Abiera</h3>
						<p className="text-center">CEO / Co-Founder</p>
					</div>
		​
					<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
						<img className="img-fluid mb-2" src="/images/company/Dzung.jpg" alt="" />
						<h3 className="text-center public-title-secondary">Dzung Nguyen</h3>
						<p className="text-center">CTO / Co-Founder</p>
					</div>
		​
					<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
						<img className="img-fluid mb-2" src="/images/company/Dennis.jpg" alt="" />
						<h3 className="text-center public-title-secondary">Dennis Meinhardt</h3>
						<p className="text-center">Advisor</p>
					</div>			
				</div>
			</div>
		</section>
		​
		<section className="container">
				<div className="row">
					<div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
						<img className="img-fluid mb-2" src="/images/company/Diane.jpg" alt="" />
						<h3 className="text-center public-title-secondary">Diane Martinez</h3>
						<p className="text-center">Sr. Product Manager</p>
					</div>
		​
					<div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
						<img className="img-fluid mb-2" src="/images/company/Natasha.jpg" alt="" />
						<h3 className="text-center public-title-secondary">Natasha Kraljevski</h3>
						<p className="text-center">Sr. Marketing Manager</p>
					</div>
		​
					<div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
						<img className="img-fluid mb-2" src="/images/company/Sheena.jpg" alt="" />
						<h3 className="text-center public-title-secondary">Sheena Marquez</h3>
						<p className="text-center">Sr. Software Engineer</p>
					</div>	
		​
					<div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
						<img className="img-fluid mb-2" src="/images/company/Luc.jpg" alt="" />
						<h3 className="text-center public-title-secondary">Luc Marion</h3>
						<p className="text-center">Web Developer</p>
					</div>			
				</div>
		</section>

	</div>
    
	)
}

export default About;