import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavBar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import NavDropdown from "react-bootstrap/NavDropdown";


const Header = () => {
    let navigate = useNavigate();


    return (
        <>

	<NavBar collapseOnSelect expand="md" bg="white" variant="white">
		<div className="container-fluid">
			<NavBar.Brand className="mx-3 navbar-brand" href="/">
				<img className="img-fluid" height={"30px"} width={"240px"} src="/images/brinkfolio-logo.svg" alt="brinkfolio logo" />
			</NavBar.Brand>
			
			<NavBar.Toggle aria-controls="responsive-NavBar-nav" />
			<NavBar.Collapse id="responsive-NavBar-na">
				<Nav className="my-2 col-8 col-md-auto mx-auto m-md-0 ms-md-auto d-flex align-items-center">
						<a href="/pressrelease" className="mx-3 text-secondary text-decoration-none">Press Release</a>
						<a href="/how-to" className="mx-3 text-secondary text-decoration-none">How To</a>
						<a href="/updates" className="mx-3 text-secondary text-decoration-none">What's New?</a>
						<a href="/about" className="mx-3 text-secondary text-decoration-none">About Us</a>
						<a href="/signup" className="mx-3 text-primary text-decoration-none">Sign Up</a>
						<a href="/login" type="button" className="mx-3 text-primary text-decoration-none">Log In</a>
				</Nav>
			</NavBar.Collapse>
		</div>
	</NavBar>


        </>
    );
};

export default Header;
