import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { FaBirthdayCake } from "react-icons/fa";
import { MdAlternateEmail, MdPassword } from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";
import "./SignUp.css";
import Loader from "../../../Components/Loader/Loader";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import GoogleButton from 'react-google-button'


// imports for authentication
import { Timestamp } from "@firebase/firestore";
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { auth, db } from "../../../firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "../../../features/userSlice";
import { addDoc, collection } from "firebase/firestore";
// react router dom
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { selectUser } from "../../../features/userSlice";


const SignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, loading } = useSelector(selectUser);


    // captcha
    const [captcha, setCaptcha] = useState("");
    function onChange(value) {
        setCaptcha(value);
    }



    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [dob, setDob] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async(event) => {
        event.preventDefault();
        registerUser();

    }
    // Register new user
    const registerUser = () => {
        if (captcha === "") {
            alert("*captcha is required");
            return;
        }

        //Age check
        var bday=dob.split("-");
        var bday_in_milliseconds = new Date(parseInt(bday[0]), 10, parseInt(bday[1], 10) - 1 , parseInt(bday[2], 10)).getTime(); //birth-date in milliseconds
        var now = new Date().getTime(); //current time in milliseconds
        if(now - bday_in_milliseconds < 410240038000){ //410240038000 is 13 years in milliseconds
            alert("Registration is under 13 years of age")
            return;
        }

        createUserWithEmailAndPassword(auth, email, password)
            .then(
                //user created
                (userCredential) => {
                    updateProfile(auth.currentUser, {
                        firstName: firstName,
                        lastName: lastName
                    })
                        .then(
                            //name updated
                            () => {
                                addDoc(collection(db, "users"), {
                                    user_id: userCredential.user.uid,
                                    dob: dob,
                                    firstName: firstName,
                                    lastName: lastName,
                                    name: firstName + " " + lastName,
                                    displayName: firstName + " " + lastName,
                                    date_created: Timestamp.fromDate(new Date()),
                                });
                            }
                        )
                        .then(async () => {
                            await sendEmailVerification(auth.currentUser)
                                .then(() => {
                                    alert(`*verification email has been sent to ${email}!`)
                                    //user added to collection
                                    dispatch(
                                        logIn({
                                            uid: userCredential.user.uid,
                                            email: userCredential.user.email,
                                            displayName: userCredential.user.displayName,
                                            dob: dob,
                                        })
                                    );
                                    navigate("/profile");
                                }).catch((error) => {
                                    console.log(error)
                                })
                        });
                }
            )
            .catch((error) => {
                alert("*" + error.code.replace(/-/g, " "));
            });
    };


    useEffect(() => {
        if (loading === false) {
            if (user !== null) {
                navigate("/profile");
            }
        }
    }, [user, loading]);


    const provider = new GoogleAuthProvider();
    const signInwithGoogle = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                addDoc(collection(db, "users"), {
                    user_id: result.user.uid,
                    name: result.user.displayName,
                    displayName: result.user.displayName,
                    dob: '1999-12-12',
                    date_created: Timestamp.fromDate(new Date()),
                }).then(() => {
                    dispatch(
                        logIn({
                            uid: result.user.uid,
                            email: result.user.email,
                            name: result.user.displayName,
                            dob: '1999-12-12',
                        })
                    );
                }).catch((error) => {
                    console.log(error)
                })
                navigate("/profile");
            }).catch((error) => {
                console.log(error);
            })
    }


    return (
        loading === true ?
            <Loader />
            :
            <Container>
            <div className="container big-div">
                <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-center justify-content-center pt-4 pr-4">

                        <form className="bg-white col-xs-12 col-md-8 py-4 px-5" onSubmit={(handleSubmit)}>
                            <h1 className="pb-2 text-secondary border-bottom">Welcome to brinkfolio!</h1> <br/>
                            <h5 className="pb-2 text-secondary"> Register below!</h5>


                            <div className="input form-group d-flex mt-2">
                                <input 
                                    type="text" 
                                    className="input-field form-control sign-up-form" 
                                    name="firstName" 
                                    value={firstName} 
                                    required onChange={(e) => setFirstName(e.target.value)}/>
                                <label className="input-label">First Name</label>
                            </div>

                            <div className="input form-group d-flex mt-2">
                                <input 
                                    type="text" 
                                    className="input-field form-control sign-up-form" 
                                    name="lastName" 
                                    value={lastName} 
                                    required onChange={(e) => setLastName(e.target.value)}/>
                                <label className="input-label">Last Name</label>
                            </div>
                            
                            <div className="input form-group d-flex mt-2">
                                <input 
                                    type="email" 
                                    className="input-field form-control sign-up-form" 
                                    name="email" 
                                    value={email} 
                                    required onChange={(e) => setEmail(e.target.value)}/>
                                <label className="input-label">Email</label>
                            </div>


                            <div className="form-group d-flex align-items-center mt-4">
                                <input
                                    type="date"
                                    className="form-control sign-up-form"
                                    value={dob}
                                    required onChange={(e) => setDob(e.target.value)}/>
                            </div>

                            <div className="input form-group d-flex mt-2">
                                <input 
                                    type="password" 
                                    className="input-field form-control sign-up-form" 
                                    name="password"
                                    value={password}
                                    minLength="8"
                                    required  onChange={(e) => setPassword(e.target.value)}/>
                                <label className="input-label">Password</label>
                            </div>

                            <div className="form-check d-flex justify-content-center mt-4 mb-3">
                                
                                <label className="form-check-label" htmlFor="terms">
                                    By clicking Sign up, I agree all of the statements in brinkfolio's {" "}
                                    <span
                                        className="text-decoration-underline text-primary"
                                        onClick={() => navigate("/privacypolicy")}
                                    >
                                        Privacy Policy
                                    </span>
                                    {" "}
                                    and
                                    {" "}
                                    <span
                                        className="text-decoration-underline text-primary"
                                        onClick={() => navigate("/termsofservice")}
                                    >
                                        Terms of Service
                                    </span>
                                </label>
                            </div>

                            <div className="col-12 d-flex justify-content-center mb-3">
                                <div className="reCaptcha">
                                    <ReCAPTCHA
                                        id="captcha1"
                                        sitekey="6LeDIpgiAAAAAHMPb4lCKzqogeFp3mQXF7pr0wje"
                                        required onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="action col-12 d-flex justify-content-center">
                                <button 
                                    className="btn btn-primary" 
                                    type="submit">Sign Up</button>
                            </div>

                            <div className="col-12 d-flex justify-content-center mt-4">
                                <GoogleButton
                                    label='Sign Up With Google'
                                    onClick={() => signInwithGoogle()}
                                />
                            </div>
                            <div className="col-12 text-center mt-4">
                                <p>
                                    Already have an account? &nbsp;
                                    <span
                                        role={"button"}
                                        className="text-primary text-decoration-underline"
                                        onClick={() => navigate("/login")}
                                    >
                                        Log in
                                    </span>
                                </p>
                            </div>
                        </form >
                    </div>

                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 border-start">
                        <img class="img-fluid" src="/images/welcome_home.png" alt="brinkfolio welcome image" />
                    </div>
                </div>
            </div>
            </Container>
    );
};


const Container = styled.div`
    margin-top: 50px;

    *, *:after, *:before {
        box-sizing: border-box;
    }

    body {
        font-family: "DM Sans", sans-serif;
        line-height: 1.5;
        background-color: #f1f3fb;
        padding: 0 2rem;
    }

    img {
        max-width: 100%;
        display: block;
    }

    .choice {
        text-align: center;
        padding-bottom: 5px;
    }


    // iOS Reset 
    input {
        appearance: none;
        border-radius: 0;
    }

    

    .card {
        margin: 1rem auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 400px;
        max-width: 425px;
        background-color: #FFF;
        border-radius: 10px;
        box-shadow: 0 10px 20px 0 rgba(#999, .25);
        padding: .75rem;
    }

    .card-bg {
        border-radius: 8px;
        overflow: hidden;
        padding-bottom: 50%;
        background-image: url();
        background-repeat: no-repeat;
        background-size: 150%;
        background-position: 0 5%;
        background-color: rgba(145, 226, 194, .5);
        background-color: rgba(105, 155, 216, .5);
        position: relative;
    }

    .card-header {
        position: absolute;
        left: 10%;
        top: 20%;
        right: 10%;
        font-size: 1.75rem;
        font-weight: 700;
        color: #00377A;
        line-height: 1.222;
        small {
            display: block;
            font-size: .75em;
            font-weight: 400;
            margin-top: .25em;
        }
    }

    .card-form {
        padding: 2rem 1rem 0;
    }

    .input {
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        padding-top: 1.0rem;
        &+.input {
            margin-top: 1.5rem;
        }
    }

    .input-label {
        color: #8597a3;
        position: absolute;
        top: 1rem;
        transition: .25s ease;
    }

    .input-field {
        border: 0;
        z-index: 1;
        background-color: transparent;
        border-bottom: 2px solid #eee; 
        font: inherit;
        font-size: 1.125rem;
        padding: .25rem 0;
        &:focus, &:valid {
            outline: 0;
            border-bottom-color: #6658d3;
            &+.input-label {
                color: #6658d3;
                transform: translateY(-1.5rem);
            }
        }
    }

    .action {
        margin-top: 1.5rem;
    }

    .action-button {
        font: inherit;
        font-size: 1.25rem;
        padding: .5em;
        width: 50%;
        font-weight: 500;
        border-radius: 15px;
        color: #FFF;
        border: 0;
        &:focus {
            outline: 0;
        }

        background-color: rgba(111, 85, 184, .8);
        margin-bottom: 20px;
        &:hover {
            background-color: rgba(111, 85, 184, 1);
            color: #fff;
            text-decoration: none;
        }




    }

    .card-info {
        padding: 1rem 1rem;
        text-align: center;
        font-size: .875rem;
        color: #8597a3;
        a {
            display: block;
            color: #6658d3;
            text-decoration: none;
        }
    }

`;

export default SignUp;