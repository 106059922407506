import React, { useEffect } from 'react'

const PressRelease = () => {
    return (
		<div className="big-div">
			<section className="container mt-5">
				<div className="row">
					<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
						<h1 className="public-title">Press Release</h1>
						<h2 className="public-title-secondary">Get the latest scoop on brinkfolio</h2>
					</div>
					<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
							<img className="img-fluid float-end" alt="Breaking news from brinkfolio!" src="/images/company/pressRelease.png" />
							<p className="figure-caption text-end">Image by pikisuperstar / Freepik</p>
					</div>
				</div>
			</section>
			<hr />
			<section className="container mb-5">
				<div className="row">
					<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
						<h3 className="public-title">Nov 03, 2022</h3>
						<h4 className="public-title-secondary">brinkfolio heads to <a href="https://startupspace.app/detail-normal-events/37101#googtrans(en)" target="_blank">SpartUp Incubator Speaker Series</a></h4>
						<p>brinkfolio is showcasing our visual portfolio app to <a href="https://www.sjsu.edu/innovation/spartup/index.php" target="_blank">SJSU students</a></p>
					</div>
					<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
						<figure>
						<img className="img-fluid" alt="brinkfolio showing our visual portfolio platform" src="/images/company/SpartUs.jpg" />
						<figcaption className="figure-caption text-end">brinkfolio presenting on SJSU Ideation & Innovation panel</figcaption>
					</figure>
					</div>
				</div>
				<hr />     
				<div className="row">
					<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
						<h3 className="public-title">Oct 28 - 30, 2022</h3>
						<h4 className="public-title-secondary">brinkfolio will be down for maintenance</h4>
						<p>brinkfolio is working hard to improve your visual portfolios! <br />Our app is down, but don't worry, when we're back up we'll be better than ever!</p>
					</div>
					<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
					<figure>
						<img className="img-fluid" alt="brinkfolio is down and working hard to improve your visual portfolios" src="/images/company/SystemDown.jpg" />
						<figcaption className="figure-caption text-end">brinkfolio will go down for a short period</figcaption>
					</figure>
					</div>
				</div>
				<hr /> 
				<div className="row">
					<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
						<h3 className="public-title">Oct 22, 2022</h3>
						<h4 className="public-title-secondary">brinkfolio heads to CSU, Long Beach for #CSUDemoDay</h4>
						<p>brinkfolio went to California State University, Long Beach for #CSUDemoDay powered by <a href="https://www.linkedin.com/company/sunstonemanagement/" target="_blank">Sunstone management</a></p>
					</div>
					<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
					<figure>
						<img className="img-fluid" alt="Our senior marketing manager, Natasha, eager to demonstrate brinkfolio" src="/images/company/CSUDemoDay.jpg" />
						<figcaption className="figure-caption text-end">brinkfolio demonstrates our app on #CSUDemoDay</figcaption>
					</figure>
					</div>
				</div>
					<hr /> 
				<div className="row">
					<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
						<h3 className="public-title">Oct 08, 2022</h3>
						<h4 className="public-title-secondary">brinkfolio heads to <a href="https://www.linkedin.com/in/sjsubmes/" target="_blank">SJSU BMES</a></h4>
						<p>Thank you Dr. Guna Selvaduray and Maya Papez for inviting brinkfolio to <a href="https://www.linkedin.com/school/san-jose-state-university/" target="_blank">San Jose State University</a> to share how to create a visual portfolio</p>
					</div>
					<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
						<figure>
						<img className="img-fluid" alt="brinkfolio CEO (Hubert) and CTO (Dzung) are invited to SJSU to share brinkfolio with SJSU students" src="/images/company/SJSUBMES.jpg" />
						</figure>
						<figcaption className="figure-caption text-end">brinkfolio empowering studuents to create a visual portfolio</figcaption>
					</div>
				</div>
			</section>
		</div>
    )
}

export default PressRelease;