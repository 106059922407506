import React from "react";
import { useNavigate } from "react-router-dom";



const Footer = () => {

    return (

        <footer className="w-100 mt-3 mb-3">
            <div className="text-center">
                <span className="small"><img src="/images/brinkfolio_icon.svg" alt="brinkfolio logo" height="20px" width="20px"/></span>
                <span className="ms-2">© 2022 brinkfolio, Inc. | Contact us: <a href="mailto:hello@brinkfolio.com">hello@brinkfolio.com</a></span>
            </div>
            <div className="w-100 text-center">
                <span>Made with ❤️ with remote hands</span>
            </div>
            <div className="w-100 text-center">
                <a className="text-decoration-none" href="/privacypolicy">Privacy Policy</a> | 
                <a className="text-decoration-none" href="/termsofservice"> Terms of Service</a>
            </div>
        </footer>
    );
};
        
export default Footer;
