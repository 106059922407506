import React, { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import "./AddGalleryImage.css";
import { v4 } from "uuid";
// firebase imports
import { db, storage } from "../../../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { Timestamp } from "@firebase/firestore";

// rich text editor
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SmallLoader from "../../Loader/SmallLoader";
import ValidateImage from "../../ValidateImage/ValidateImage";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";
import ConfirmModalBackdrop from "../../ConfirmModal/ConfirmModalBackdrop";
import ReactTooltip from 'react-tooltip';
import imageCompression from 'browser-image-compression';

const AddGalleryImage = ({
    index,
    userName,
    oldGallery,
    gallery,
    projectId,
    userId,
    setAddImages,
    setAllowEdit
}) => {

    const x = gallery[index].desc ? EditorState.createWithContent(convertFromRaw(JSON.parse(gallery[index].desc))) : EditorState.createEmpty();
    const [description, setDescription] = useState(x);

    const [image, setImage] = useState(gallery[index].img ? gallery[index].img : "");
    const [imagePreview, setImagePreview] = useState(gallery[index].img ? gallery[index].img : "");
    const [disable, setDisable] = useState(false);

    const addProjectSubmit = async () => {
        setDisable(true);
        addImageToStorage(image);
    };

    const deleteOldProjectImage = async (oldImage) => {
        try {
            await deleteObject(ref(storage, oldImage));
        } catch (error) {
            console.log(error.code);
        }
    }

    const addImageToStorage = async (image) => {
        if (image === gallery[index].img) {
            if (image === "" || typeof image === "undefined") {
                addDocumentToDatabase("");
                return;
            }
            addDocumentToDatabase(image);
            return;
        }

        // add new image
        const imageRef = ref(
            storage,
            `project-images/${userId}/${v4()}`
        );

        //compression details
        const imageFile = image;
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            const uploadTask = uploadBytesResumable(imageRef, compressedFile);
            uploadTask.on(
                "state-changed",

                (snapshot) => {
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                },
                

                (error) => {
                    // error in image upload
                    alert(">>> " + error);
                },

                () => {
                    // successful image upload and add image link to document
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        addDocumentToDatabase(downloadURL);
                        if (gallery[index].img) {
                            const fileRef = ref(storage, gallery[index].img);
                            deleteObject(fileRef).then(() => {
                            }).catch((error) => {
                                console.log(error)
                            })
                        }
                    });
                }
            );
        } catch (error) {
            console.log(error.code);
        }
    };

    const addDocumentToDatabase = async (imgLink) => {
        const updatedGallery = [...oldGallery];
        updatedGallery[index] = { img: imgLink }; //, desc: JSON.stringify(convertToRaw(description.getCurrentContent())) };  //functionality for adding description to image

        await updateDoc(doc(db, "projects", projectId), {
            gallery: updatedGallery,
            date_lastUpdated: Timestamp.fromDate(new Date())
        }).then(() => {
            setDisable(true);
            setAddImages(false);
            setAllowEdit(false)
        }).catch((error) => {
            alert(error)
            setAddImages(false);
            setAllowEdit(false)
        });
        window.location.reload();
    };


    const initial = {
        image: gallery[index].img ? gallery[index].img : "",
        description: x,
    };

    const [confirm, setConfirm] = useState(false);
    const close = () => {
        if (
            image === initial.image &&
            description === initial.description
        ) {
            setAddImages(false);
            setAllowEdit(false);
        } else {
            setConfirm(true);
        }
    };

    // Delete Image
    const deleteImageSubmit = async () => {
        setDisable(true);

        // delete old image
        if (gallery[index].img) {
            const fileRef = ref(storage, gallery[index].img);
            await deleteObject(fileRef).then(() => {
                console.log("old img deleted")
            }).catch((error) => {
                console.log(error)
            })
            addDocumentToDatabase(""); //replace image reference with empty string
        }
    };


    return (

        <div
            className="AddProject position-fixed top-50 start-50 translate-middle bg-white rounded-4 py-3 ps-5 pe-2 col-11 col-md-10 col-lg-6 d-flex flex-column"
            style={{ height: "55vh", zIndex: "20" }}
        >

            {
                confirm &&
                <>
                    <ConfirmModalBackdrop />
                    <ConfirmModal setShowMainModal={setAddImages} setShowConfirmModal={setConfirm} />
                </>
            }

            <div className="pe-4" style={{ overflow: "auto" }}>
                {/* heading and exit */}
                <div className="container-fluid mg-0 p-0 d-flex justify-content-between align-items-center">
                    <h2 className="fw-bold m-0">Gallery</h2>
                    <AiFillCloseCircle size="30px" className="edit-img-hvr" onClick={() => close()} />
                </div>


                {/* image */}
                <div
                    className="rounded-4 overflow-hidden mt-2 mx-auto border position-relative d-flex align-items-center justify-content-center edit-img-hvr"
                    data-for="editImage"
                            data-tip
                    style={{ width: "18vmax", height: "18vmax" }}
                >
                    {imagePreview === "" ? (
                        <img src="/images/upload.svg" style={{ width: "30%", height: "30%" }} alt="" />
                    ) : (
                        <img
                            src={imagePreview}
                            alt="Image_Preview"
                            style={{ width: "auto", height: "100%" }}
                        />
                    )}

                

                    <ValidateImage
                        setImagePreview={setImagePreview}
                        setImage={setImage}
                    />
                </div>
                    
                <div className="text-center">Upload Image: <span style={{ color: 'red' }}> .png .jpg | Max size: 10mb</span></div>
                <div className="text-center mb-3"> Optimal resolution: <span style={{ color: 'red' }}>at least 500x500px </span></div>
                <ReactTooltip id="editImage" place="right" effect="solid">
                            Upload image
                    </ReactTooltip>
                {/* editor  HIDE FOR NOW, NOT BEING USED
                <div className="col-7 border p-2 overflow-auto mb-auto">
                    <Editor
                        editorState={description}
                        placeholder='Type here or press ctrl + V to paste your content'
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName bg-light px-2"
                        onEditorStateChange={(editorState) => {
                            setDescription(editorState);
                        }}

                        toolbar={{
                            options: ["inline", "list", "textAlign", "history"],
                        }}
                    />
                </div>
                    */}

                {/* buttons */}
                <div className="container-fluid d-flex mb-5 p-0 m-0">
                    
                    <button
                        onClick={deleteImageSubmit}
                        data-for="deleteImage"
                        data-tip
                        className="btn btn-sm btn-outline-danger px-3"
                        disabled={disable}
                    >
                        {
                            disable === true ?
                                <SmallLoader />
                                :
                                "Delete"
                        }
                    </button>

                    <ReactTooltip id="deleteImage" place="right" effect="solid" backgroundColor="darkred">
                        This cannot be undone!
                    </ReactTooltip>

                    <div className="ms-auto">
                        <button
                            onClick={addProjectSubmit}
                            className="btn-primary px-4"
                        >
                            {
                                disable === true ?
                                    <SmallLoader />
                                    :
                                    "Save"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddGalleryImage;
