import React, { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import "./EditProject.css";
import ValidateImage from "../../ValidateImage/ValidateImage";
import { v4 } from "uuid";

// firebase imports
import { Timestamp } from "@firebase/firestore";
import { db, storage } from "../../../firebaseConfig";
import { deleteObject, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
    getDoc, doc, updateDoc, collection,
    getDocs,
    query,
    where,
    deleteDoc,
    writeBatch,
} from "firebase/firestore";

import { useNavigate } from "react-router-dom";
import SmallLoader from "../../Loader/SmallLoader";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";
import ConfirmDeleteModal from "../../ConfirmModal/ConfirmDeleteModal";
import ConfirmModalBackdrop from "../../ConfirmModal/ConfirmModalBackdrop";

import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import ReactTooltip from 'react-tooltip';
import { isEmpty } from "@firebase/util";
import imageCompression from 'browser-image-compression';

const EditProject = ({ userId, project, setEditProject }) => {
    const initial = {
        image: project.image,
        name: project.name,
        details: project.details,
        summary: project.summary,
        role: project.role,
        order: project.order,
        featured: project.is_featured,
        gallery: project.gallery,
    };
    const details_coverted = project.details ? EditorState.createWithContent(convertFromRaw(JSON.parse(project.details))) : EditorState.createEmpty();

    const [confirm, setConfirm] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    
    const close = () => {
        const details_raw = JSON.stringify(convertToRaw(details.getCurrentContent()));
        if (
            image === initial.image &&
            name === initial.name &&
            details_raw === initial.details && //details is formatted differently so it won't match
            summary === initial.summary &&
            role === initial.role &&
            order === initial.order &&
            featured === initial.featured
        ) {
            setEditProject(false);
        } else {
            setConfirm(true);
        }
    };

    const deleteCheck = () => {
        setShowConfirmDelete(true);

        if(confirmDelete) {
            //deleteProjectSubmit();
        }
    };

    
    const [errorMsg, setErrorMsg] = useState("");
    const [imagePreview, setImagePreview] = useState(project.image);
    const [image, setImage] = useState(project.image);
    const [name, setName] = useState(project.name);
    const [details, setDetails] = useState(details_coverted);
    const [summary, setSummary] = useState(project.summary);
    const [role, setRole] = useState(project.role);
    const [order, setOrder] = useState(project.order);
    const [featured, setFeatured] = useState(project.is_featured);
    const [gallery, setGallery] = useState(project.gallery);

    const navigate = useNavigate();


//retrieve gallery of images
    const getProjectGallery = async () => {
        const docRef = doc(db, "projects", project.docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            var tempGallery = [];
            docSnap.data().gallery.map((image_desc) =>
                tempGallery.push(image_desc)
            );
        } else {
            console.log("Document does not exist !");
        }
        setGallery(tempGallery);
    };

    const deleteProjectSubmit = async () => {
        setDisable(true);

        deleteProjectImages();
        deleteProject();
        //navigate('/profile');
    }

    //delete project gallery
    const deleteProjectImages = async () => {
        for(let i = 0; i < gallery.length; i++) {
            if(!isEmpty(gallery[i])){
                if(gallery[i].img !== '') {
                    console.log(gallery[i]);
                    try {
                        deleteObject(ref(storage, gallery[i].img));
                    } catch (error) {
                        console.log('error deleting image: ' + gallery[i].img );
                    }
                }
            }
        }
    }
    // Delete project
    const deleteProject = async () => {
        try {
            const querySnapshot2 = await getDoc(doc(db, "projects", project.docId));
            if (querySnapshot2.data().image !== "") {
                await deleteObject(ref(storage, querySnapshot2.data().image));
            }
            //delete post
            await deleteDoc(doc(db, "projects", project.docId));
            setDisable(false);
            setEditProject(false);
            navigate('/profile');
        } catch (error) {
            console.log(error.code);
        }
        window.location.reload();
    };

    const deleteOldProjectImage = async (oldImage) => {
        try {
            await deleteObject(ref(storage, oldImage));
        } catch (error) {
            console.log(error.code);
        }
    }

    const updateProjectSubmit = async () => {
        if (
            name === "" ||
            image === "" ||
            summary === "" ||
            details === ""
        ) {
            setErrorMsg("Please add a name, image, summary, and details");
            return;
        }
        
        setDisable(true);
        addImageToStorage();
    };

    const addImageToStorage = async () => {
        if (image === project.image) {
            addDocumentToDatabase(image); //call add document function
            return;
        }

        const imageRef = ref(
            storage,
            `project-images/${userId}/${v4()}`
        );
        
        //compression details
        const imageFile = image;
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }

        try {
            const compressedFile = await imageCompression(imageFile, options)
            const uploadTask = uploadBytesResumable(imageRef, compressedFile)
                    
            uploadTask.on(
                "state-changed",

                
                (snapshot) => {
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                },
                

                (error) => {
                    // error in image upload
                    alert(">>> " + error);
                },

                () => {
                    // successful image upload
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        addDocumentToDatabase(downloadURL); //call add document function
                        if(initial.image !== "") {
                            deleteOldProjectImage(initial.image);
                        }
                    });
                }
            );
        } catch (error) {
            console.log(error);
        }
    };

    const addDocumentToDatabase = async (imageLink) => {
        await updateDoc(doc(db, "projects", project.docId), {
            name: name,
            image: imageLink,
            details: JSON.stringify(convertToRaw(details.getCurrentContent())),
            summary: summary,
            role: role,
            order: order || "",
            is_featured: featured,
            created_by: userId,
            date_lastUpdated: Timestamp.fromDate(new Date())
        }).then(() => {
            setDisable(false);
            setEditProject(false);
        }).catch((error) => {
            alert(error.code);
        });
        window.location.reload();
    };

    const [disable, setDisable] = useState(false);

    return (
        <div
            className="EditProject position-fixed top-50 start-50 translate-middle bg-white rounded-4 py-3 ps-5 pe-2 col-10 col-md-8 col-lg-6 d-flex flex-column"
            style={{ height: "95vh", zIndex: "20" }}
        >

            {
                confirm &&
                <>
                    <ConfirmModalBackdrop />
                    <ConfirmModal setShowMainModal={setEditProject} setShowConfirmModal={setConfirm} />
                </>
            }

            {
                showConfirmDelete &&
                <>
                    <ConfirmModalBackdrop />
                    <ConfirmDeleteModal setShowMainModal={setEditProject} setShowConfirmModal={setShowConfirmDelete}/>
                </>
            }

            <div className="pe-4" style={{ overflow: "auto" }}>

                {/* heading and exit */}
                <div className="container-fluid mg-0 p-0 d-flex justify-content-between align-items-center">
                    <h2 className="fw-bold m-0">Edit Project</h2>
                    <AiFillCloseCircle
                        size="30px"
                        className="edit-img-hvr"
                        onClick={() => close()}
                    />
                </div>

                {/* image */}
                <div
                    className="rounded-4 overflow-hidden mt-2 mx-auto position-relative d-flex align-items-center justify-content-center edit-img-hvr"
                    data-for="editImage"
                    data-tip
                    style={{ width: "18vmax", height: "18vmax" }}
                >
                    <img
                        src={imagePreview}
                        alt="Image_Preview"
                        style={{ width: "auto", height: "100%" }}
                    />
                    <ValidateImage setImagePreview={setImagePreview} setImage={setImage} />
                </div>
                <div className="text-center">Upload Image: <span style={{ color: 'red' }}> .png .jpg | Max size: 10mb</span></div>
                <div className="text-center mb-3"> Optimal resolution: <span style={{ color: 'red' }}>at least 500x500px </span></div>

                <ReactTooltip id="editImage" place="right" effect="solid">
                        Upload image
                </ReactTooltip>

                <p style={{fontSize:"12px"}}>* required field</p>
                {/* name */}
                <div className="form-group mt-1">
                    <label htmlFor="name" className="small">Project Name <span>*</span>
                    </label>
                    <input
                        type="text"
                        className="form-control mt-1 py-1"
                        value={name}
                        maxLength="50"
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>

                {/* summary */}
                <div className="form-group mt-1">
                    <label className="small">Project Summary <span>*</span></label>
                    <textarea
                        className="form-control mt-1 py-1"
                        value={summary}
                        maxLength="300"
                        placeholder="Tell us about your project. 300 characters max"
                        rows="4"
                        onChange={(e) => setSummary(e.target.value)}
                    />
                </div>
                {/* details */}
                <div className="form-group mt-1">
                <label className="small">Project Details <span>*</span></label>
                    <div className="col-20 border p-2 overflow-auto mb-auto">
                        <Editor
                            editorState={details}
                            placeholder='Type here or press ctrl + V to paste your content'
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName bg-light"
                            onEditorStateChange={(editorState) => {
                                setDetails(editorState);
                            }}

                            toolbar={{
                                options: ["inline", "list", "textAlign", "history"],
                            }}
                        />
                    </div>
                </div>

                {/* role */}
                <div className="form-group mt-1 mb-3">
                    <label className="small">Role</label>
                    <input
                        type="text"
                        className="form-control mt-1 py-1"
                        value={role}
                        maxLength="30"
                        onChange={(e) => setRole(e.target.value)}
                    />
                </div>

                {/* order */}
                <div className="form-group mt-1 mb-3">
                    <label className="small">Position</label>
                    <input
                        type="number"
                        className="form-control mt-1 py-1"
                        value={order}
                        placeholder="Type in a number between 1 and 100"
                        min="1" max="100"
                        maxLength="10"
                        error={order > 12 ? 'Enter a number less than 12' : ''}
                        onChange={(e) => setOrder(e.target.value)}
                    />
                </div>

                { (errorMsg !== "" ? 
                <h6 className="p-2 border text-center justify-content-center rounded-3" style={{ backgroundColor: '#DE5654',color: 'white' }}>{errorMsg}</h6> : <p></p>
                )}

                {/* buttons */}
                <div className="container-fluid d-flex mt-5 p-0 m-0">
                    <button
                        onClick={deleteProjectSubmit}
                        data-for="deleteProject"
                        data-tip
                        className="btn btn-sm btn-outline-danger px-3"
                        disabled={disable}
                    >
                        {
                            disable === true ?
                                <SmallLoader />
                                :
                                "Delete"
                        }
                    </button>

                    <ReactTooltip id="deleteProject" place="right" effect="solid" backgroundColor="darkred">
                        This cannot be undone!
                    </ReactTooltip>

                    <div className="ms-auto">
                        <input
                            type="checkbox"
                            defaultChecked={project.is_featured}
                            onChange={(e) => setFeatured(e.target.checked)}
                        />
                        <span className="me-3 px-1">Featured Project</span>

                        <button
                            onClick={updateProjectSubmit}
                            className="btn-primary px-4"
                            disabled={disable}
                        >
                            {
                                disable === true ?
                                    <SmallLoader />
                                    :
                                    "Save"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProject;
