import React from 'react'

const Survey = () => {
    return (
		<div className="big-div">
			<section class="container text-center ">
				<h1 className="public-title">My experience using brinkfolio</h1>
				<section class="container">
					<div class="row justify-content-center">
						<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfxdd4xenzEawXFP7nAMc8ZQxlUaP57rn0xDfgt5HSbJJpThQ/viewform?embedded=true" width="640" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
					</div>
				</section>
			</section>
		</div>
    )
}

export default Survey;