import React, { useEffect, useState } from "react";
import Project from "../../Components/Project/Project";
import Backdrop from "../../Components/Backdrop/Backdrop";
import { useSelector } from "react-redux";
// react router dom
import { selectUser } from "../../features/userSlice";
import { useNavigate, useParams } from 'react-router-dom';
// firebase imports
import { db } from "../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import ViewResume from "../../Components/ViewOverlays/ViewResume/ViewResume";
import ViewContact from "../../Components/ViewOverlays/ViewContact/ViewContact";
import Loader from "../../Components/Loader/Loader";

const UserProfile = () => {
    let params = useParams();
    const { user, loading } = useSelector(selectUser);


    // profile data
    const [displayName, setDisplayName] = useState("");
    const [image, setImage] = useState("");
    const [description, setDescription] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [website, setWebsite] = useState("");
    const [instagram, setInstagram] = useState("");
    const [youtube, setYoutube] = useState("");
    const [github, setGithub] = useState("");
    const [tiktok, setTiktok] = useState("");
    const [resume, setResume] = useState("");


    // projects data
    const [featuredProjects, setFeaturedProjects] = useState([]);
    const [projects, setProjects] = useState([]);

    // reume , contact toggle
    const [viewResume, setViewResume] = useState(false);
    const [viewContact, setViewContact] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        const getUserData = async () => {
            // console.log("-->" + params.id)
            const querySnapshot = await getDocs(
                query(
                    collection(db, "users"),
                    where("user_id", "==", params.id)
                )
            );
            
            if (querySnapshot.docs[0] === undefined) {
                navigate('/');
            }


            // console.log(querySnapshot.docs[0].data)
            setDisplayName(querySnapshot.docs[0].data().displayName);
            setImage(querySnapshot.docs[0].data().image);
            setDescription(querySnapshot.docs[0].data().description);
            setEmail(querySnapshot.docs[0].data().email);
            setPhone(querySnapshot.docs[0].data().phone);
            setLinkedIn(querySnapshot.docs[0].data().linkedIn);
            setWebsite(querySnapshot.docs[0].data().website);
            setInstagram(querySnapshot.docs[0].data().instagram);
            setYoutube(querySnapshot.docs[0].data().youtube);
            setGithub(querySnapshot.docs[0].data().github);
            setTiktok(querySnapshot.docs[0].data().tiktok);
            setResume(querySnapshot.docs[0].data().resume);
            
        };

        const getUserProjects = async () => {
            const querySnapshot = await getDocs(
                query(
                    collection(db, "projects"),
                    where("created_by", "==", params.id),
                    orderBy('date_lastUpdated', 'desc'),
                )
            );

            var projTempArr = [];
            var featProjTempArr = [];

            querySnapshot.docs.map(
                (doc) => (
                    doc.data().is_featured === false
                        ?
                        projTempArr.push({
                            doc_id: doc.id,
                            doc_data: doc.data(),
                        })
                        :
                        featProjTempArr.push({
                            doc_id: doc.id,
                            doc_data: doc.data(),
                        })
                )
            )
            //sort depending on order field
            featProjTempArr.sort((a,b) => a.doc_data.order - b.doc_data.order);
            projTempArr.sort((a,b) => a.doc_data.order - b.doc_data.order);

            setProjects(projTempArr);
            setFeaturedProjects(featProjTempArr);
        };


        getUserData();
        getUserProjects();
        return () => {
            console.log("");
        }
    }, [params.id]);



    return (
        loading === true ?
            <Loader />
            :
            <div className="UserProfile profile-bg">

                {   //view resume
                    viewResume && (
                        <>
                            <Backdrop />
                            <ViewResume resume={resume} setViewResume={setViewResume} />
                        </>
                    )
                }

                {   //view contact
                    viewContact && (
                        <>
                            <Backdrop />
                            <ViewContact contact={{ email, phone, linkedIn, website, instagram, youtube, github, tiktok }} setViewContact={setViewContact} />
                        </>
                    )
                }

                {/* PROFILE SECTION */}
                <div className="container col-11 my-3 p-3 d-flex flex-column align-items-center rounded-4 border position-relative bg-white">
                    {/* Name */}
                    <h2 className="mt-5 mb-3 fw-bold profile-title">{displayName}</h2>

                    {/* image */}
                    <div
                    
                        className="rounded-circle overflow-hidden bg-light d-flex flex-column align-items-center"
                        style={{ width: "10vmax", height: "10vmax", minWidth: "150px", minHeight: "150px", maxWidth: "300px", maxHeight: "300px" }}
                    >
                        {image !== "" ? (
                            <img src={image} className="h-100 w-auto" alt="" />
                        ) : (
                            <img src="/images/profile.svg" className="h-100 w-auto" alt="" />
                        )}
                    </div>

                    {/* decription */}
                    <p className="mt-3 col-8 text-center">{description}</p>

                    {/* follow icon */}
                    { /*
                        user &&
                        <button className="btn btn-sm rounded-0 btn-light border py-0 position-absolute top-0 end-0 me-5 mt-4">Follow</button>
                    */
                    }

                    <div className="position-absolute top-0 start-0 ms-5 mt-3">
                        <button
                            className="btn btn-md rounded-5 btn-light border py-0 me-4"
                            onClick={() => setViewContact(true)}
                        >
                            Contact
                        </button>
                        {/* TEMPORARY DISABLE
                        <button
                            className="btn btn-sm rounded-0 btn-light border py-0"
                            onClick={() => setViewResume(true)}
                        >
                            View Resume
                        </button>
                        */}
                    </div>
                </div>

                {/* FEATURED PROJECTS SECTION */}
                <div className="container col-11 my-3 py-3 px-4 rounded-4 border position-relative bg-white">

                    {/* heading */}
                    <h3 className="container-fluid mb-5 profile-title">Featured Projects</h3>

                    {/* projects */}
                    <div className="row">
                        {
                            featuredProjects.length > 0
                                ?
                                featuredProjects.map(({ doc_id, doc_data }) => (
                                    <div
                                        className="col-12 my-2 col-md-4 d-flex justify-content-center"
                                    > 
                                    
                                        <Project
                                            key={doc_id}
                                            docId={doc_id}
                                            project={doc_data}
                                            allowEdit={false}
                                        />
                                    
                                    </div>
                                ))
                                :
                                <h3 style={{ textAlign: "center", color: "#CED0D4", marginTop: "50px" }}>Under construction</h3>
                        }
                    </div>
                </div>

                {/* PROJECTS SECTION */}
                <div className="container col-11 my-3 py-3 px-4 rounded-4 border position-relative bg-white">
                    {/* edit icon */}

                    {/* heading */}
                    <h3 className="container-fluid mb-5 profile-title">Projects</h3>

                    {/* projects */}
                    <div className="row">
                        {
                            projects.length > 0
                                ?
                                projects.map(({ doc_id, doc_data }) => (
                                <div
                                    className="col-12 my-2 col-md-4 d-flex justify-content-center"
                                >
                                        <Project
                                            key={doc_id}
                                            docId={doc_id}
                                            project={doc_data}
                                            allowEdit={false}
                                        />
                                    </div>
                                ))
                                :
                                <h3 style={{ textAlign: "center", color: "#CED0D4", marginTop: "50px" }}>Under construction</h3>
                        }
                    </div>
                </div>
            </div>
    );
};

export default UserProfile;
