import React from 'react';
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import "./Project.css";
import ReactTooltip from 'react-tooltip';

import { LazyLoadImage } from 'react-lazy-load-image-component';

const Project = ({ docId, allowEdit, displayOrder, project, setEditProject }) => {
    var projectData = project;
    projectData["docId"] = docId;

    const navigate = useNavigate();
    return (
        
        <div className='Project text-center position-relative p-0 mb-5 mx-4' style={{ width: "20vmax", minWidth: "200px" }}>
            {/* edit icon */
                allowEdit &&
                
                <BiDotsHorizontalRounded
                    className="position-absolute edit-btn"
                    size="20px"
                    data-for="edit"
                    data-tip
                    style={{ top: "2px", right: "0" }}
                    onClick={() => setEditProject(projectData)}
                    role="button"
                />
            }

            <ReactTooltip id="edit" place="right" effect="solid">
                Edit Project
            </ReactTooltip>


            {/* name */}
            <h5 className='p-0 px-3 mb-4'>{project.name}</h5>

            {/* image */}
            <div
                className="rounded-4 overflow-hidden d-flex flex-column align-items-center edit-img-hvr"
                style={{ width: "20Vmax", height: "20vmax", minWidth: "180px", minHeight: "180px", maxWidth: "360px", maxHeight: "360px" }}
                onClick={
                    () => navigate(`/project/${docId}`)
                }
            >
                <LazyLoadImage
                    width={360} height={360}
                    className="project-img h-100 w-auto"
                    src={project.image !== "" ? project.image : "/noImage.png"}
                    alt="Project Image"
                    style={{ width: "20vmax", height: "20vmax", minWidth: "180px", minHeight: "180px" }}
                />
            </div>

            {/* Summary */}
            <p className="mt-4 text-start">
                {project.summary}
            </p>

            {
                displayOrder && project.order &&
                <p className='p-0 px-3 mb-4' style={{ fontWeight: '600'}}>*Position #{project.order}</p>
            }


        </div>
    )
}

export default Project