import React, { useEffect } from 'react'

const Howto = () => {
    return (
		<div className="big-div">
<section className="container-fluid mt-3 p-5 bg-purple-light">
	<div className="row">
		<div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 pe-5">
			<div>Your guide to</div>
			<h1 className="public-title mb-3">Creating a eye-catching portfolio</h1>
			<p>Don't have a brinkfolio account? Sign up for an early invitation.</p>
			<a href="/signup">
				<button type="button" className="btn btn-primary my-3" aria-label="Sign up for a brinkfolio account">Sign me up</button>
			</a>
		</div>
		<div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
			<img className="img-fluid" src="/images/howto/femaleGuide.png" alt="Female guide" />
		</div>
	</div>
</section>
<section className="container-fluid mt-4">
	<div className="row">
		<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5 pt-5">
			<div className="pb-3">
				<h2 className="public-title-secondary">1. What Makes You Special Pops Right Away</h2>
			</div>
			<ul className="pe-4">
				<li>Succinctly show what makes you different:</li>
					<ul className="mb-3">
						<li>The projects that you are proudest of</li>
						<li>Activities that separate you from the rest</li>
						<li>Projects that show your style (hands on, analytical, experimental, etc.</li>
					</ul>
				<li>Conversations with Hiring Managers is why we built the “Featured”section into brinkfolio &mdash; so prospective employees can present their brand</li>
			</ul>
			<p className="fw-bolder">Let's take a look at Kristen's featured section to see an example of this approach</p>
		</div>
		<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-5">
			<img className="img-fluid" src="/images/howto/KristenFeatured.jpg" alt="screenshot of Kristen Umbriac's portfolio" />
		</div>
	</div>
</section>
<section className="container-fluid pt-4 bg-blue-vlight">
	<div className="row">
		<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
			<div className="pb-3">
				<h2 className="public-title-secondary">2. You Can Present Your Projects With Ease Over Video Interviews</h2>
			</div>
			<ul className="pe-4">
				<li>Know where your projects are!</li>
					<ul className="mb-3">
						<li>Looking for a particular project wastes time in interviews</li>
					</ul>
				<li>Aside from your featured projects, your portfolio should track your resume</li>
					<ul className="mb-3">
						<li>Most likely will be chronological</li>
					</ul>
			</ul>
			<p className="fw-bolder">Youngmoon's portfolio is a great example of a chronnological approach</p>
		</div>
		<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 pb-5">
			<img className="img-fluid" src="/images/howto/YoungmoonProjects.jpg" alt="screenshot of Youngmoon Choe's portfolio" />
		</div>
	</div>
</section>
<section className="container-fluid pt-4">
	<div className="row">
		<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5">
			<div className="pb-3">
				<h2 className="public-title-secondary">3. No Contribution Ambiguity</h2>
			</div>
			<ul className="pe-4">
				<li>Hiring Managers want to know what YOU did</li>
					<ul className="mb-3">
						<li>Engineering projects tend to be multi personnel, so explain your actual role</li>
						<li>Do not use "we" language</li>
						<li>Distinguish between your contribution and the rest of your team’s contributions</li>
					</ul>
				<li>Most importantly: Do not claim credit for the parts of the project that you did not work on!</li>
			</ul>
			<p className="fw-bolder">Let's take a look at this project's details.</p>
		</div>
		<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5 pb-4">
			<img className="img-fluid" src="/images/howto/projectDetails.jpg" alt="screenshot of project details" />
		</div>
	</div>
</section>
<section className="container-fluid pt-5 bg-blue-vlight">
	<div className="row">
		<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 px-5 text-center">
			<div className="pb-3">
				<h2 className="public-title-secondary">Bonus: Your Portfolio Tells a Story</h2>
			</div>
			<p className="pe-4">Your career is a path that only you have taken. Your resume + your portfolio should be able to tell your story!</p>
			<img className="img-fluid py-5"  src="/images/howto/featuredPortfolios.jpg" alt="Overview of Dennis, Marco, and Kristen's portfolios" />
		</div>
	</div>
</section>
		</div>
    )
}

export default Howto;