import { Link } from "react-router-dom";

export default function NotFound() {
    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-5 p-0 mt-5 d-flex align-items-center justify-content-center pt-4">

                    <div className="bg-white col-xs-12 col-md-10 py-4 px-5 rounded-3 border align-items-center justify-content-center ">
                        <h4 className="pb-2 text-secondary border-bottom ">Oops! This page doesn't exist.</h4>
                        <h5 className="pb-2 mt-3 text-secondary"> Maybe you can hang out here instead? </h5>
                        <h5 className="pb-2 mt-3 text-secondary"> <Link to='/'>Home</Link></h5>
                    </div>

                </div>

            </div>


        </div>
    )
}