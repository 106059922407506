import React, { useEffect, useState, useCallback } from "react";
import { TbEdit, TbUser, TbArrowBackUp } from "react-icons/tb";
import { IoAddCircleOutline } from "react-icons/io5";
import EditProject from "../../Components/Forms/EditProject/EditProject";
import Backdrop from "../../Components/Backdrop/Backdrop";
import { useSelector } from "react-redux";
// react router dom
import { useNavigate, useParams } from "react-router-dom";
// firebase imports
import { selectUser } from "../../features/userSlice";
import { db } from "../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import Loader from "../../Components/Loader/Loader";
import AddGalleryImage from "../../Components/Forms/AddGalleryImage/AddGalleryImage";
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import ReactTooltip from 'react-tooltip';
import ImageViewer from 'react-simple-image-viewer';

const ProjectPage = () => {
    let params = useParams();

    const { user, loading } = useSelector(selectUser);

    //const details_coverted = project.details ? EditorState.createWithContent(convertFromRaw(JSON.parse(project.details))) : EditorState.createEmpty();
    // project data
    const projectId = params.id;
    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [summary, setSummary] = useState("");
    const [details, setDetails] = useState("");
    const [role, setRole] = useState("");
    const [featured, setFeatured] = useState("");
    const [gallery, setGallery] = useState([]);
    const [createdBy, setCreatedBy] = useState("");
    const [order, setOrder] = useState("");
    const [url , setUserURL] = useState("");

    const [ viewerGallery, setViewerGallery] = useState([]);
    const [ currentImageIndex, setCurrentImageIndex] = useState(0);
    const [ isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback ((index) => {
        const tmpGallery = [];

        gallery.forEach((value) => { 
            
            /* doesn't work on prod
            if(value.img === "" || value.img === undefined) {
                tmpGallery.push(window.location.protocol + "//" + window.location.host + "/images/noimage.png");
            } else {
                
            }
            */
            tmpGallery.push(value.img);
        })

        setViewerGallery(tmpGallery);
        setCurrentImageIndex(index);
        setIsViewerOpen (true);
    }, [gallery]);

    const closeImageViewer = () => {
        setCurrentImageIndex(0);
        setIsViewerOpen(false);
    }


    // toggle forms
    const [editProject, setEditProject] = useState(false);
    const [addImages, setAddImages] = useState(false);
    const [allowEdit, setAllowEdit] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const getUserData = async () => {
            // fetch user data
            const docRef = doc(db, "projects", projectId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setName(docSnap.data().name);
                setImage(docSnap.data().image);
                setDetails(docSnap.data().details);
                setSummary(docSnap.data().summary);
                setRole(docSnap.data().role);
                setFeatured(docSnap.data().is_featured);
                setCreatedBy(docSnap.data().created_by);
                setOrder(docSnap.data().order);

                //need to get proper user from project page
                /*
                if(user && createdBy === user.uid) {
                    console.log("loged in");
                    var url = "/profile";
                } else {
                    var url = "/user/" + user.uid;
                }
                console.log(url);
                setUserURL(url);
                */

                var tempGallery = [];
                docSnap.data().gallery.map((image_desc) =>
                    tempGallery.push(image_desc)
                );
                setGallery(tempGallery);
            } else {
                navigate('/');
                console.log("Document does not exist !");
            }
        };

        getUserData();

        
        
        return () => {
            console.log("");
        };
    }, [projectId]); //avoid pulling data again when using these --- , editProject, addImages]);

    var parseDetails = "";
    if(details !== "") {
        parseDetails = parse(draftToHtml(JSON.parse(details)));
    }

    const OpenAddGallery = (i) => {
        setAddImages(i);
    }

    return (
        loading === true ?
            <Loader />
            :
            <div className="Profile profile-bg">
                {
                    //edit project
                    editProject !== false && (
                        <>
                            <Backdrop />
                            <EditProject
                                project={{
                                    name: name, image: image, summary: summary, details: details, role: role, order: order, is_featured: featured, docId: projectId, gallery: gallery
                                }}
                                userId={user.uid}
                                setEditProject={setEditProject}
                            />
                        </>
                    )
                }
                {
                    //add images
                    addImages !== false && (
                        <>
                            <Backdrop />
                            <AddGalleryImage
                                index={addImages}
                                userId={user.uid}
                                projectId={params.id}
                                userName={user.name}
                                oldGallery={gallery}
                                gallery={gallery}
                                setAddImages={setAddImages}
                                setAllowEdit={setAllowEdit}
                            />
                        </>
                    )
                }

                {/* PROJECT SECTION */}
                <div className="container col-11 my-3 p-3 pb-5 rounded-4 border position-relative bg-white">
                    
                    { /* back icon 
                        <TbArrowBackUp
                            className="position-absolute edit-btn"
                            size="30px"
                            data-for="editProject"
                            onClick={() => navigate(url)}
                            role="button"
                        />
                        */
                    }

                    {/* Name */}
                    <h2 className="ms-4 mb-5 px-4 fw-bold profile-title">{name}</h2>

                    <div className="col-12 px-5 d-flex flex-wrap">
                        {/* image */}
                        <div className="col-12 col-md-6 d-flex justify-content-center">
                            <span
                                className="rounded-4 overflow-hidden bg-light d-flex flex-column align-items-center"
                                style={{ width: "25vmax", height: "25vmax" }}
                            >
                                <img
                                    className="h-100 w-auto"
                                    src={image !== "" ? image : "profile.png"}
                                    alt={"User_Image"}
                                />
                            </span>
                        </div>

                        <div className="col-12 col-md-6 mt-3 ps-5 mt-ms-0 d-flex flex-column justify-content-between">
                            <div className="container">
                                <h5>Description</h5>
                                <div>{parseDetails}</div>
                            </div>

                        {(role !== "" ?
                            <div className="container">
                                <h5>Role</h5>
                                <p>{role}</p>
                            </div>
                        : <div></div>)
                        }
                        </div>
                    </div>

                    { /* edit icon */
                        (user && (createdBy === user.uid)) &&
                        <TbEdit
                            className="position-absolute edit-btn"
                            size="30px"
                            data-for="editProject"
                            data-tip
                            style={{ top: "10px", right: "20px" }}
                            onClick={() => setEditProject(true)}
                            role="button"
                        />
                    }

                    <ReactTooltip id="editProject" place="right" effect="solid" backgroundColor="black">
                        Edit Project
                    </ReactTooltip>

                </div>

                <div className="container col-11 my-3 py-3 px-4 rounded-4 border position-relative bg-white">

                    {/* edit icon  Only visible if logged */ 
                        (user && (createdBy === user.uid)) &&
                        <IoAddCircleOutline
                            className="position-absolute edit-btn"
                            size="30px"
                            data-for="editGallery"
                            data-tip
                            style={{ top: "10px", right: "20px" }}
                            onClick={() => {
                                if (allowEdit) {
                                    setAllowEdit(false)
                                } else {
                                    setAllowEdit(true)
                                }
                            }}
                            role="button"
                        />
                    }

                    <ReactTooltip id="editGallery" place="right" effect="solid" backgroundColor="black">
                        Edit Gallery
                    </ReactTooltip>

                    {/* heading */}
                    <h3 className="container-fluid mb-3 profile-title">Gallery</h3>

                    {/* images THIS IS CAUSING A UNIQUE KEY PROP ERROR ( USE REACT FRAGMENT) */}
                    <div className="d-flex justify-content-md-equal flex-wrap p-5">
                        {
                            (user && (createdBy === user.uid)) ?
                                (gallery.length > 0 ?
                                    <>
                                        {[...Array(6)].map((x, i) => (
                                            <div className="col-12 my-2 col-md-4 d-flex justify-content-center">
                                                <div
                                                    className="m-2 mb-5 rounded-4 border overflow-hidden d-flex flex-column align-items-center position-relative"
                                                    style={{ width: "18vmax", height: "18vmax" }}
                                                    key={i}
                                                >
                                                    {allowEdit && <div className="position-absolute rounded-4 top-0 start-0 h-100 w-100 bg-white opacity-50 d-flex align-items-center justify-content-center p-0 m-0 edit-img-hvr"
                                                        onClick={() => OpenAddGallery(i)}
                                                    >
                                                        <h1 style={{ fontSize: "50px" }} className="p-0 m-0 opacity-75 pb-2">+</h1>
                                                    </div>
                                                    }

                                                    { (gallery[i].img?  
                                                        <img
                                                            className="h-100 w-auto edit-img-hvr"
                                                            onClick={ () => openImageViewer(i)}
                                                            src={gallery[i].img}
                                                            alt="gallery_image"
                                                        />
                                                        :
                                                        <img
                                                            className="h-100 w-auto"
                                                            src="../noImage.png"
                                                            alt="gallery_image"
                                                        />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            color: "#CED0D4",
                                            marginTop: "50px",
                                        }}
                                    >
                                        Under construction
                                    </h3>
                                )
                                :
                                (gallery.length > 0 ? 
                                    <>
                                        {gallery.map(({ img }, i) => (
                                            img &&
                                            <div className="col-12 my-2 col-md-4 d-flex justify-content-center">
                                                <div
                                                    className="m-2 rounded-4 border overflow-hidden d-flex flex-column align-items-center position-relative"
                                                    style={{ width: "18vmax", height: "18vmax" }}
                                                    
                                                    key={i}
                                                >
                                                    <img
                                                        className="h-100 w-auto"
                                                        onClick={ () => openImageViewer(i)}
                                                        src={img}
                                                        alt="gallery_image"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <h2
                                        style={{
                                            textAlign: "center",
                                            color: "#CED0D4",
                                            marginTop: "50px",
                                        }}
                                    >
                                        Under construction
                                    </h2>
                                )
                        }

                        {isViewerOpen && (
                            <ImageViewer
                            src={ viewerGallery }
                            currentIndex={ currentImageIndex }
                            disableScroll={ false }
                            closeOnClickOutside={ true }
                            onClose={ closeImageViewer }
                            />
                        )}      

                    </div>

                    

                    
                </div>

            </div>
    );
};

export default ProjectPage;
