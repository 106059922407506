import React from 'react'
import "./Welcome.css";

const Welcome = () => {

    return (
		<div className="big-div">
<section className="container-fluid pt-5">
	<div className="row">
		<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5 pt-5 bg-purple-light">
			<h1 className="public-title">Get the attention you deserve</h1>
			<p>brinkfolio gives professionals the tools to visually share their story with easy to create portfolios. Showcase and share your creative and career milestones with professionals across industries.</p>
			<a href="/signup">
				<button type="button" className="btn btn-primary mt-3 mb-5" aria-label="Sign up for a brinkfolio account">Sign me up</button>
			</a>
		</div>
		<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5 py-3">
			<img className="img-fluid" alt="Sample brinkfolio portfolio" src="/images/company/home/kristen.jpg" />
		</div>
	</div>
</section>
<section className="container-fluid">
	<div className="row bg-blue-vlight">
		<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 py-5">
			<div className="p-3">
				<h2 className="public-title-alt text-center fw-light">Used by professionals at</h2>
			</div>
			<div className="text-center fs-4 fw-light">
				<p>San Jose State University | PlayStation | Ally Robotics | UC Berkeley</p>
			</div>
		</div>
	</div>
</section>
<section className="container py-3">
	<div className="row pt-5">
		<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 pb-5">
			<div className="pb-3">
				<h2 className="public-title-secondary text-center">Easy to create professional <br />portfolios</h2>
			</div>
			<img src="/images/company/home/mandevelopingapp.jpg" className="img-fluid px-4" alt="man quickly creating a professional portfolio" />
			<p className="figure-caption text-center">Image by Freepik</p>
		</div>
​
		<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 pb-5">
			<div className="pb-3">
				<h2 className="public-title-secondary text-center">Share projects that cannot be contained in a resume</h2>
			</div>
			<img src="/images/company/home/womanprogrammingrobot.jpg" className="img-fluid px-4" alt="woman programming her robot" />
			<p className="figure-caption text-center">Image by pch.vector / Freepik</p>
		</div>
​
		<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 pb-5">
			<div className="pb-3">
				<h2 className="public-title-secondary text-center">Dynamic professional pages to showcase your work</h2>
			</div>
			<img src="/images/company/home/portfoliostandsout.jpg" className="img-fluid px-4" alt="A visual portfolio stands out from the competition" />
			<p className="figure-caption text-center">Image by atemangostar / Freepik</p>
		</div>
	</div>
</section>
<section className="container-fluid pt-5 bg-blue-vlight">
	<div className="row px-5">
		<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
			<figure className="text-center">
				<img className="img-fluid rounded-circle" alt="Beverly Wilt profile image" src="/images/company/home/beverly.jpg" />
  				<blockquote className="blockquote mt-3 fst-italic">
					<p>"I've spent HOURS perfecting my old portfolio, but my brinkfolio one took a franction of that and showcases my projects just as effectively, if not more so!"</p>
				</blockquote>
				<figcaption className="blockquote-footer mb-5">
					Beverly Wilt
				</figcaption>
			</figure>
		</div>
		<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
			<figure className="text-center">
				<img className="img-fluid rounded-circle" alt="Dennis Meinhardt profile image" src="/images/company/home/dennis.jpg" />
  				<blockquote className="blockquote mt-3 fst-italic">
					<p>"I am a visual person, and a picture is worth more than a thousand words. As a hiring manager of designers and developers, having people with visual content representing their work is super helpful. Hubert and the team at brinkfolio are making this easier for more people to express themselves professionally and it is awesome."</p>
				</blockquote>
				<figcaption className="blockquote-footer mb-5">
					Dennis Meinhardt
				</figcaption>
			</figure>
		</div>
		<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12">
			<figure className="text-center">
				<img className="img-fluid rounded-circle" alt="Amy Melman profile image" src="/images/company/home/amy.jpg" />
  				<blockquote className="blockquote mt-3 fst-italic">
					<p>"As a young and inexperienced student, brinkfolio made it possible to demonstrate my abilities to hiring firms and secure my first internship interview, ever."</p>
				</blockquote>
				<figcaption className="blockquote-footer">
					Amy Melman
				</figcaption>
			</figure>
		</div>
	</div>
</section>
<section className="container-fluid mb-3">
	<div className="row">
		<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5 text-center">
			<img className="img-fluid mx-5 mt-2" alt="Create brinkfolio account" src="/images/company/home/createaccount.jpg" />
			<p className="figure-caption text-center">Image by Freepik</p>
		</div>
		<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5 pt-5 bg-purple-light">
			<h2 className="public-title">Create your brinkfolio account today!</h2>
			<p>Stand out from other candidates by sharing the visuals of your work</p>
			<p>Interested in standing out from the competition?</p>
			<a href="/signup">
				<button type="button" className="btn btn-primary mb-5" aria-label="Sign up for a brinkfolio account">Sign me up</button>
			</a>
		</div>
	</div>
</section>
		</div>
    )
}

export default Welcome;