import React, { useCallback, useEffect, useReducer, useState } from "react";
import { TbEdit } from "react-icons/tb";
import { IoAddCircleOutline } from "react-icons/io5";
import EditProfile from "../../Components/Forms/EditProfile/EditProfile";
import Project from "../../Components/Project/Project";
import EditProject from "../../Components/Forms/EditProject/EditProject";
import Backdrop from "../../Components/Backdrop/Backdrop";
import AddProject from "../../Components/Forms/AddProject/AddProject";
import { useSelector } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import "./MyProfile.css";
// react router dom
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../features/userSlice";
// firebase imports
import { auth, db } from "../../firebaseConfig";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import ReactTooltip from 'react-tooltip';
import ViewContact from "../../Components/ViewOverlays/ViewContact/ViewContact";
import {useQuery} from 'react-query';

function test() {

}

const MyProfile = () => {

    // const [reload, setReload] = useReducer(x => x + 1, 0);
    
    const navigate = useNavigate();
    const { user, loading } = useSelector(selectUser);
    const [userId, setUserId] = useState(null);
    const [viewContact, setViewContact] = useState(false);
    // profile data
    const [docId, setDocId] = useState(null);
    const [displayName, setDisplayName] = useState("");
    const [image, setImage] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [website, setWebsite] = useState("");
    const [instagram, setInstagram] = useState("");
    const [youtube, setYoutube] = useState("");
    const [github, setGithub] = useState("");
    const [tiktok, setTiktok] = useState("");
    const [description, setDescription] = useState("");

    // projects data
    const [featuredProjects, setFeaturedProjects] = useState([]);
    const [projects, setProjects] = useState([]);

    // toggle forms
    const [editProfile, setEditProfile] = useState(false);
    const [editProject, setEditProject] = useState(false);
    const [addFeaturedProject, setAddFeaturedProject] = useState(false);
    const [addProject, setAddProject] = useState(false);

    const [gallery, setGallery] = useState([]);

    /*
    const querySnapshot = async () => getDocs(
        await query(
            collection(db, "users"),
            where("user_id", "==", user.uid)
        )
    );

    const { isLoading, error, data } = useQuery({
        queryKey:['userData'],
        queryFn: () => querySnapshot });
    console.log(data);
    return;
        */

    useEffect(() => {
        const getUserData = async () => {
            setUserId(user.uid);

            // fetch user data
            /**/ 
            const querySnapshot = await getDocs(
                query(
                    collection(db, "users"),
                    where("user_id", "==", user.uid)
                )
            );
            
            setDocId(querySnapshot.docs[0].id);
            setDisplayName(querySnapshot.docs[0].data().displayName);
            setImage(querySnapshot.docs[0].data().image);
            setEmail(querySnapshot.docs[0].data().email);
            setPhone(querySnapshot.docs[0].data().phone);
            setLinkedIn(querySnapshot.docs[0].data().linkedIn);
            setWebsite(querySnapshot.docs[0].data().website);
            setInstagram(querySnapshot.docs[0].data().instagram);
            setYoutube(querySnapshot.docs[0].data().youtube);
            setGithub(querySnapshot.docs[0].data().github);
            setTiktok(querySnapshot.docs[0].data().tiktok);
            setDescription(querySnapshot.docs[0].data().description);
            setGallery(querySnapshot.docs[0].data().gallery);
        };

        const getUserProjects = async () => {
            const querySnapshot = await getDocs(
                query(
                    collection(db, "projects"),
                    where("created_by", "==", user.uid),
                    orderBy('date_lastUpdated', 'desc'),
                )
            );

            var projTempArr = [];
            var featProjTempArr = [];

            querySnapshot.docs.map(
                (doc) => (
                    doc.data().is_featured === false
                        ?
                        projTempArr.push({
                            doc_id: doc.id,
                            doc_data: doc.data(),
                        })
                        :
                        featProjTempArr.push({
                            doc_id: doc.id,
                            doc_data: doc.data(),
                        })
                )
            )

            //sort depending on order field
            featProjTempArr.sort((a,b) => a.doc_data.order - b.doc_data.order);
            projTempArr.sort((a,b) => a.doc_data.order - b.doc_data.order);

            setProjects(projTempArr);
            setFeaturedProjects(featProjTempArr);

        };

        if (loading === false) {
            if (user === null) {
                navigate("/");
            }
            else {
                setFeaturedProjects([]);
                setProjects([]);
                getUserData();
                getUserProjects();
                return () => {
                    //console.log("");
                }
            }
        }
    }, [user]); //This causes too many refreshes and therefore reads because of the useeffect, editProfile, editProject, addProject, addFeaturedProject]);



    return (
        loading === true ?
            <Loader />
            :
            
            <div className="profile-bg">
                {
                    //edit profile
                    editProfile && (
                        <>
                            <Backdrop />
                            <EditProfile
                                user={{ userId, docId, displayName, image, description, email, phone, linkedIn, website, instagram, youtube, github, tiktok }}
                                setEditProfile={setEditProfile}
                                setDisplayNameMain={setDisplayName}
                                setImageMain={setImage}
                                setDescriptionMain={setDescription}
                            />
                        </>
                    )
                }


                {
                    //edit project
                    editProject !== false && (
                        <>
                            <Backdrop />
                            <EditProject
                                project={editProject}
                                userId={userId}
                                setEditProject={setEditProject}
                            />
                        </>
                    )
                }

                {
                    //add featured project
                    addFeaturedProject !== false && (
                        <>
                            <Backdrop />
                            <AddProject
                                userId={userId}
                                isFeatured={true}
                                setAddProject={setAddFeaturedProject}
                            />
                        </>
                    )
                }

                {
                    //add project
                    addProject !== false && (
                        <>
                            <Backdrop />
                            <AddProject userId={userId} isFeatured={false} setAddProject={setAddProject} />
                        </>
                    )
                }

                {   //view contact
                    viewContact && (
                        <>
                            <Backdrop />
                            <ViewContact contact={{ email, phone, linkedIn, website, instagram, youtube, github, tiktok }} setViewContact={setViewContact} />
                        </>
                    )
                }

                {/* PROFILE SECTION */}
                <div className="container col-11 my-3 p-3 d-flex flex-column align-items-center rounded-4 border position-relative bg-white">
                    {/* Name */}
                    <h2 className="mt-5 mb-3 fw-bold profile-title">{displayName}</h2>

                    {/* image */}

                    <div
                        className="rounded-circle overflow-hidden bg-light d-flex flex-column align-items-center"
                        style={{ width: "10vmax", height: "10vmax", minWidth: "150px", minHeight: "150px", maxWidth: "300px", maxHeight: "300px" }}
                    >
                        {image !== "" && image !== undefined ? (
                            <img src={image} className="h-100 w-auto" alt="" />
                        ) : (
                            <img src="/images/profile.svg" className="h-100 w-auto" alt="" />
                        )}
 
                    </div>

                    {/* decription */}
                    <p className="mt-3 col-8 text-center">{description}</p>

                    {/* edit icon */}

                    <TbEdit
                        className="position-absolute edit-btn"
                        size="30px"
                        data-for="editProfile"
                        data-tip
                        style={{ top: "10px", right: "20px" }}
                        onClick={() => setEditProfile(true)}
                        role="button"
                    />

                    <ReactTooltip id="editProfile" place="right" effect="solid">
                        Edit Profile
                    </ReactTooltip>

                    <div className="position-absolute top-0 start-0 ms-4 mt-3">
                        <button
                            className="btn btn-md rounded-5 btn-light border py-0 me-4"
                            onClick={() => setViewContact(true)}
                        >
                            Contact
                        </button>
                        {/* TEMPORARY DISABLE
                        <button
                            className="btn btn-sm rounded-0 btn-light border py-0"
                            onClick={() => setViewResume(true)}
                        >
                            View Resume
                        </button>
                        */}
                    </div>
                </div>
                
                

                {/* FEATURED PROJECTS SECTION */}
                <div className="container col-11 my-3 py-3 px-4 rounded-4 border position-relative bg-white">
                    {/* edit icon */}
                    <IoAddCircleOutline
                        className="position-absolute edit-btn"
                        size="30px"
                        data-for="addFeaturedProject"
                        data-tip
                        style={{ top: "10px", right: "20px" }}
                        onClick={() => setAddFeaturedProject(true)}
                        role="button"
                    />

                    <ReactTooltip id="addFeaturedProject" place="right" effect="solid">
                        Add Featured Project
                    </ReactTooltip>

                    {/* heading */}
                    <h3 className="container-fluid mb-5 profile-title">Featured Projects</h3>

                    {/* projects */}
                    <div className="row">
                        {
                            featuredProjects.length > 0
                                ?
                                featuredProjects.map(({ doc_id, doc_data }) => (
                                    <div
                                        className="col-12 my-2 col-md-4 d-flex justify-content-center"
                                        key={doc_id}
                                    > <Project
                                            key={doc_id}
                                            docId={doc_id}
                                            allowEdit={true}
                                            project={doc_data}
                                            displayOrder={true}
                                            setEditProject={setEditProject}
                                        />
                                    </div>
                                ))
                                :
                                <h3 style={{ textAlign: "center", color: "#CED0D4", marginTop: "50px" }}>Add Featured Projects</h3>
                        }
                    </div>
                    <p style={{ fontWeight: '600'}}>*Only seen in edit mode</p>
                </div>

                {/* PROJECTS SECTION */}
                <div className="container col-11 my-3 py-3 px-4 rounded-4 border position-relative bg-white">
                    {/* edit icon */}
                    <IoAddCircleOutline
                        className="position-absolute edit-btn"
                        size="30px"
                        data-for="addProject"
                        data-tip
                        style={{ top: "10px", right: "20px" }}
                        onClick={() => setAddProject(true)}
                        role="button"
                    />

                    <ReactTooltip id="addProject" place="right" effect="solid">
                        Add Project
                    </ReactTooltip>

                    {/* heading */}
                    <h3 className="container-fluid mb-5 profile-title">Projects</h3>

                    {/* projects */}
                    <div className="row">
                        {
                            projects.length > 0
                                ?
                                projects.map(({ doc_id, doc_data }) => (
                                    <div
                                        className="col-12 my-2 col-md-4 d-flex justify-content-center"
                                        key={doc_id}
                                    >
                                        <Project
                                            docId={doc_id}
                                            project={doc_data}
                                            allowEdit={true}
                                            displayOrder={true}
                                            setEditProject={setEditProject}
                                        />
                                    </div>
                                ))
                                :
                                <h3 style={{ textAlign: "center", color: "#CED0D4", marginTop: "50px" }}>Add New Projects</h3>
                        }
                    </div>
                    <p style={{ fontWeight: '600'}}>*Only seen in edit mode</p>
                </div>
            </div>
        
    );
};

export default MyProfile;
