import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { MdAlternateEmail, MdPassword } from "react-icons/md";
import "./SignIn.css";
import Loader from "../../../Components/Loader/Loader";
// react router dom
import { useNavigate } from "react-router-dom";
// imports for authentication
import {
    fetchSignInMethodsForEmail,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "../../../firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";
import { logIn } from "../../../features/userSlice";
import { addDoc, collection, getDocs, limit, query, Timestamp, where } from "firebase/firestore";
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import GoogleButton from 'react-google-button'


const SignIn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, loading } = useSelector(selectUser);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [passwordReset, setPasswordReset] = useState(false);

    // captcha
    const [loginFail, setLoginFail] = useState(0);
    const [captcha, setCaptcha] = useState("");
    function onChange(value) {
        setCaptcha(value);
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
        loginUser();
    }

    // Login User
    const loginUser = () => {
        if (email === "" || password === "") {
            return;
        }
        if (loginFail > 2 && captcha === "") {
            return;
        }


        signInWithEmailAndPassword(auth, email, password)
            .then(
                //user logged in
                (userCredential) => {
                    const q = query(
                        collection(db, "users"),
                        where("user_id", "==", userCredential.user.uid),
                        limit(1)
                    );
                    //console.log("Verified? " + userCredential.user.emailVerified); //FIND A WAY TO STORE VERIFIED FOR THE FIRST TIME ***************************************************
                    getDocs(q).then((querySnapshot) => {
                        dispatch(
                            logIn({
                                uid: userCredential.user.uid,
                                email: userCredential.user.email,
                                name: userCredential.user.displayName,
                                dob: querySnapshot.docs[0].data().dob
                            })
                        );
                        setLoginFail(0);
                        navigate("/profile");
                    });
                }
            )
            .catch((error) => {
                if (error.code === "auth/wrong-password" || error.code ==="auth/user-not-found") {
                    setLoginFail(loginFail + 1)
                    setErrorMsg("Incorrect Username or Password")
                } else if (error.code === "auth/too-many-requests") {
                    setErrorMsg("Too many login attempts. Please try again in 15 minutes.");
                } else {
                    console.log("*" + error.code.replace(/-/g, " "));
                }
            });
    }

    // Forget Password
    const forgetPassword = () => {
        if (email === "") {
            setErrorMsg("Enter email to reset password");
            return;
        }

        sendPasswordResetEmail(auth, email)
            .then(() => {
                setErrorMsg("");
                setPasswordReset(true);
            })
            .catch((error) => {
                if (error.code === "auth/invalid-email")
                {
                    setErrorMsg("Incorrect Username or Password")
                }
            });
    }


    useEffect(() => {
        if (loading === false) {
            if (user !== null) {
                navigate("/profile");
            }
        }
    }, [user, loading]);


    const provider = new GoogleAuthProvider();
    const signInwithGoogle = () => {

        signInWithPopup(auth, provider)
            .then(async (result) => {
                // fetch user data
                const querySnapshot = await getDocs(
                    query(
                        collection(db, "users"),
                        where("user_id", "==", result.user.uid)
                    )
                );
                if (querySnapshot.docs.length > 0) {
                    // if user exists
                    dispatch(
                        logIn({
                            uid: result.user.uid,
                            email: result.user.email,
                            name: result.user.displayName,
                            dob: querySnapshot.docs[0].data().dob,
                        })
                    );
                } else {
                    // if user does not exist
                    console.log("*user not found");
                    addDoc(collection(db, "users"), {
                        user_id: result.user.uid,
                        name: result.user.displayName,
                        dob: '1999-12-12',
                        date_created: Timestamp.fromDate(new Date()),
                    }).then(() => {
                        console.log("*new user created");
                        dispatch(
                            logIn({
                                uid: result.user.uid,
                                email: result.user.email,
                                name: result.user.displayName,
                                dob: '1999-12-12',
                            })
                        );
                    }).catch((error) => {
                        console.log(error)
                    })
                }
                navigate("/profile");
            }).catch((error) => {
                console.log(error)
            })
        navigate("/profile");
    }


    return (
        loading === true ?
            <Loader />
            :
            <Container>
            <div className="container big-div">
                <div className="row">
                    
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-center justify-content-center pt-4 pr-4">

                        <CardForm className="bg-white" onSubmit={(handleSubmit)}>
                            
                            { (errorMsg !== "" ? 
                            <h6 className="p-2 border text-center justify-content-center rounded-3" style={{ backgroundColor: '#DE5654',color: 'white' }}>{errorMsg}</h6> : <p></p>
                            )}
                            <h1 className="pb-2 text-secondary border-bottom">Welcome to brinkfolio!</h1>

                            <CardInput>
                                <InputField 
                                    type="email" 
                                    name="email" 
                                    value={email} 
                                    required onChange={(e) => setEmail(e.target.value)}/>
                                <label className="input-label">Email</label>
                            </CardInput>

                            <CardInput>
                                <InputField 
                                    type="password" 
                                    name="password"
                                    value={password}
                                    minLength="8"
                                    required onChange={(e) => setPassword(e.target.value)}/>
                                <label className="input-label">Password</label>
                                
                            </CardInput>
                            
                            {loginFail > 2 &&
                                <div className="col-12 mt-3 d-flex justify-content-center">
                                    <div className="reCaptcha">
                                        <ReCAPTCHA
                                            id="captcha2"
                                            sitekey="6LeDIpgiAAAAAHMPb4lCKzqogeFp3mQXF7pr0wje"
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            }

                            <div className="action col-12 d-flex justify-content-center">
                                <button className="btn btn-primary px-5" type="submit">Sign In</button>
                            </div>

                            <div className="col-12 text-center mt-3">
                                <p
                                    role={"button"}
                                    className="text-primary btn-forgot"
                                    onClick={() => navigate("/forgot")}
                                >
                                    Forgot password?
                                </p>
                            </div>

                            <div className="col-12 text-center mt-3">
                                <h4 className="pt-4 text-secondary border-top">Don't have a brinkfolio account? </h4>
                                    <span
                                        role={"button"}
                                        className="text-primary btn-signup"
                                        onClick={() => navigate("/signup")}
                                    >
                                        Sign Up
                                    </span>
                            </div>

                            {/*
                            <div className="col-12 d-flex justify-content-center mt-3">
                                <GoogleButton

                                    label='Log In With Google'
                                    onClick={() => signInwithGoogle()}
                                />
                            </div>
                            */}

                        </CardForm>

                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 border-start">
                        <img className="img-fluid" src="/images/welcome_home.png" alt="brinkfolio welcome image" />
                    </div>
                </div>


            </div>
        </Container>
    );
};

const Container = styled.div`
    margin-top: 50px;

    *, *:after, *:before {
        box-sizing: border-box;
    }

    body {
        font-family: "DM Sans", sans-serif;
        line-height: 1.5;
        background-color: #f1f3fb;
        padding: 0 2rem;
    }

    img {
        max-width: 100%;
        display: block;
    }

    .choice {
        text-align: center;
        padding-bottom: 10px;
    }


    // iOS Reset 
    input {
        appearance: none;
        border-radius: 0;
    }


    .forgot {
         margin-top: 20px;

         a {
            text-decoration: none;
         }

         &:hover {
            text-decoration: underline;
         }

    }
    .input-label {
        color: #8597a3;
        position: absolute;
        top: 1.5rem;
        transition: .25s ease;
    }

    

    .action {
        margin-top: 1.5rem;
    }

    .action-button {
        font: inherit;
        font-size: 1.25rem;
        padding: .5em;
        width: 50%;
        font-weight: 500;
        border-radius: 15px;
        color: #FFF;
        border: 0;
        &:focus {
            outline: 0;
        }

        background-color: rgba(111, 85, 184, .8);
        margin-bottom: 20px;
        &:hover {
            background-color: rgba(111, 85, 184, 1);
            color: #fff;
            text-decoration: none;
        }




    }

    .card-info {
        padding: 1rem 1rem;
        text-align: center;
        font-size: 1rem;

        color: #8597a3;
        a {
            margin-top: 5px;
            display: block;
            color: #6658d3;
            text-decoration: none;
        }
    }

`;

const CardForm = styled.form`
    padding: 2rem 1rem 0;
`;

const CardInput = styled.div`
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    padding-top: 1.5rem;
    &+.input {
        margin-top: 1.5rem;
    }
`;

const InputField = styled.input`
    border: 0;
    z-index: 1;
    background-color: transparent;
    border-bottom: 2px solid #eee; 
    font: inherit;
    font-size: 1.125rem;
    padding: .25rem 0;
    &:focus, &:valid {
        outline: 0;
        border-bottom-color: #6658d3;
        &+.input-label {
            color: #6658d3;
            transform: translateY(-1.5rem);
        }
    }
`;
export default SignIn;
