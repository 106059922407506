import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { MdAlternateEmail, MdPassword } from "react-icons/md";
import Loader from "../../../Components/Loader/Loader";
// react router dom
import { useNavigate } from "react-router-dom";
// imports for authentication
import {
    fetchSignInMethodsForEmail,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "../../../firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";
import { logIn } from "../../../features/userSlice";
import { addDoc, collection, getDocs, limit, query, Timestamp, where } from "firebase/firestore";
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import GoogleButton from 'react-google-button'


const ConfirmForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
            <Container>
            <div className="container big-div">
                <div className="row">
                    
                    
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-center justify-content-center pt-4 pr-4">

                        <CardForm className="bg-white">
                            
                            <h1 className="pb-2 text-secondary border-bottom">Password Reset</h1>
                            <p>A reset password email has been sent!</p>

                            <div className="action col-12 d-flex justify-content-center">
                                <p>
                                    <span
                                        role={"button"}
                                        className="text-primary text-decoration-underline"
                                        onClick={() => navigate("/login")}
                                    >
                                        Log in
                                    </span>
                                </p>
                            </div>
                        </CardForm>

                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 border-start">
                        <img class="img-fluid" src="/images/welcome_home.png" alt="brinkfolio welcome image" />
                    </div>
                </div>


            </div>
        </Container>
    );
};

const Container = styled.div`
    margin-top: 50px;

    *, *:after, *:before {
        box-sizing: border-box;
    }

    body {
        font-family: "DM Sans", sans-serif;
        line-height: 1.5;
        background-color: #f1f3fb;
        padding: 0 2rem;
    }

    img {
        max-width: 100%;
        display: block;
    }

    .choice {
        text-align: center;
        padding-bottom: 10px;
    }


    // iOS Reset 
    input {
        appearance: none;
        border-radius: 0;
    }


    .forgot {
         margin-top: 20px;

         a {
            text-decoration: none;
         }

         &:hover {
            text-decoration: underline;
         }

    }
    .input-label {
        color: #8597a3;
        position: absolute;
        top: 1.5rem;
        transition: .25s ease;
    }

    

    .action {
        margin-top: 1.5rem;
    }

    .action-button {
        font: inherit;
        font-size: 1.25rem;
        padding: .5em;
        width: 50%;
        font-weight: 500;
        border-radius: 15px;
        color: #FFF;
        border: 0;
        &:focus {
            outline: 0;
        }

        background-color: rgba(111, 85, 184, .8);
        margin-bottom: 20px;
        &:hover {
            background-color: rgba(111, 85, 184, 1);
            color: #fff;
            text-decoration: none;
        }




    }

    .card-info {
        padding: 1rem 1rem;
        text-align: center;
        font-size: 1rem;

        color: #8597a3;
        a {
            margin-top: 5px;
            display: block;
            color: #6658d3;
            text-decoration: none;
        }
    }

`;

const CardForm = styled.form`
    padding: 2rem 1rem 0;
`;

const CardInput = styled.div`
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    padding-top: 1.5rem;
    &+.input {
        margin-top: 1.5rem;
    }
`;

const InputField = styled.input`
    border: 0;
    z-index: 1;
    background-color: transparent;
    border-bottom: 2px solid #eee; 
    font: inherit;
    font-size: 1.125rem;
    padding: .25rem 0;
    &:focus, &:valid {
        outline: 0;
        border-bottom-color: #6658d3;
        &+.input-label {
            color: #6658d3;
            transform: translateY(-1.5rem);
        }
    }
`;
export default ConfirmForgotPassword;
