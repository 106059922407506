import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { getAuth } from "firebase/auth"
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyDSNobtkPi_e2nDgpeJtsSLkzC-ScQzpsM",
  authDomain: "brinkfolio-live.firebaseapp.com",
  databaseURL: "https://brinkfolio-live-default-rtdb.firebaseio.com",
  projectId: "brinkfolio-live",
  storageBucket: "brinkfolio-live.appspot.com",
  messagingSenderId: "2157230038",
  appId: "1:2157230038:web:e888e9dac743dd41a75677",
  measurementId: "G-GDW7YYMDS7"
};

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const storage = getStorage(app)
const auth = getAuth(app)
const analytics = getAnalytics(app);
const perf = getPerformance(app);
export { db, storage, auth, analytics }