import React from 'react'

const Backdrop = () => {
    return (
        <div className="Backdrop position-fixed top-0 start-0 w-100 bg-black opacity-50"
            style={{ height: "100%", zIndex: "10" }}
        ></div>
    )
}

export default Backdrop