import React from 'react';
import { AiFillCloseCircle, AiFillLinkedin, AiFillInstagram, AiFillYoutube, AiOutlineLink, AiFillGithub, AiOutlineMail, AiFillPhone } from "react-icons/ai";
import { CgWebsite } from "react-icons/cg";
import { FaTiktok } from "react-icons/fa";

const ViewContact = ({ contact, setViewContact }) => {

    return (
        <div className="AddProject position-fixed top-50 start-50 translate-middle bg-white rounded-4 py-3 ps-4 pe-2 col-6 col-md-6 col-lg-5 d-flex flex-column"
            style={{ minHeight: "40vh", maxHeight:"80vh", zIndex: "20" }}
        >
            <div className="pe-4" style={{ overflow: "auto" }}>

                {/* heading and exit */}
                <div className="container-fluid mg-0 p-0 d-flex justify-content-between align-items-center mb-5">
                    <h2 className="fw-bold m-0">Contact</h2>
                    <AiFillCloseCircle size="30px" onClick={() => setViewContact(false)} />
                </div>
                
                <div className="mx-3 align-items-center justify-content-between text-center">
                    <div className="row mb-3">

                        { contact.email ? ( 
                            <div className="my-2 col-md-4 justify-content-center">
                                <div className="mt-2">
                                    <a href={"mailto:" + contact.email + "?subject=brinkfolio Profile"} style={{ color: 'black' }}><AiOutlineMail size="60px" /></a>
                                    <p>Email</p>
                                </div>
                            </div>
                            ) : 
                            <div></div>
                        }

                        { contact.phone ? (
                            <div className="my-2 col-md-4 justify-content-center">
                                <div className="mt-2">
                                    <a href={"tel:" + contact.phone} style={{ color: 'black' }}><AiFillPhone size="60px" /></a>
                                    <p>Phone</p>
                                </div>
                            </div>
                            ) : 
                            <div></div>
                        }
                        
                        { contact.linkedIn ? (
                            <div className="my-2 col-md-4 justify-content-center">
                                <div className="mt-2">
                                    <a href={contact.linkedIn}><AiFillLinkedin size="60px" /></a>
                                    <p>LinkedIn</p>
                                </div>
                            </div>
                            ) : 
                            <div></div>
                        }
                    
                    
                        { contact.website ? (
                            <div className="my-2 col-md-4 justify-content-center">
                                <div className="mt-2">
                                    <a href={contact.website} style={{ color: 'black' }}><AiOutlineLink size="60px" /></a> 
                                    <p>Website</p>
                                </div>
                            </div>
                            ) : 
                            <div></div>
                        }
                    
                    
                        { contact.instagram ? (
                            <div className="my-2 col-md-4 justify-content-center">
                                <div className="mt-2">
                                    <a href={contact.instagram} style={{ color: 'black' }}><AiFillInstagram size="60px" /></a>
                                    <p>Instagram</p>
                                </div>
                            </div>
                            ) : 
                            <div></div>
                        }
                        
                        { contact.youtube ? (
                            <div className="my-2 col-md-4 justify-content-center">
                                <div className="mt-2">
                                    <a href={contact.youtube} style={{ color: 'red' }}><AiFillYoutube size="60px"  /></a> 
                                    <p>Youtube</p>
                                </div>
                            </div>
                            ) : 
                            <div></div>
                        }
                        
                        { contact.github ? (
                            <div className="my-2 col-md-4 justify-content-center">
                                <div className="mt-2">
                                    <a href={contact.github} style={{ color: 'black' }}><AiFillGithub size="60px" /></a> 
                                    <p>Github</p>
                                </div>
                            </div>
                            ) : 
                            <div></div>
                        }
                        
                        
                        { contact.tiktok ? (
                            <div className="my-2 col-md-4 d-flex justify-content-center">
                                <div className="mt-2">
                                    <a href={contact.tiktok} style={{ color: 'black' }}><FaTiktok size="60px" /></a> 
                                    <p>TikTok</p>
                                </div>
                            </div>
                            ) : 
                            <div></div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewContact;